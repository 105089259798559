define('frontend/components/navbar-search-input/component', ['exports', 'ember-component'], function (exports, _emberComponent) {

  var KEYCODE_ENTER = 13;

  exports['default'] = _emberComponent['default'].extend({
    classNames: ['c-search-input'],

    searchInput: '',

    'on-input': function onInput() {},

    keyUp: function keyUp(ev) {
      ev.preventDefault();

      var keyCode = ev.keyCode || ev.which;

      if (keyCode === KEYCODE_ENTER) {
        this.get('on-input')(this.get('searchInput'));
      }
    }
  });
});