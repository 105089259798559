define('frontend/routes/edit-user', ['exports', 'ember-computed', 'ember-route', 'ember-service/inject', 'rsvp', 'frontend/utils/user-roles'], function (exports, _emberComputed, _emberRoute, _emberServiceInject, _rsvp, _frontendUtilsUserRoles) {
  exports['default'] = _emberRoute['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),

    currentUserRole: (0, _emberComputed.readOnly)('currentUser.user.role'),

    redirect: function redirect() {
      var role = this.get('currentUserRole');

      if (![_frontendUtilsUserRoles.ADMIN, _frontendUtilsUserRoles.DEALER_MANAGER].includes(role)) {
        this.transitionTo('dashboard');
      }
    },

    model: function model(_ref) {
      var id = _ref.id;

      var role = this.get('currentUserRole');

      if (_frontendUtilsUserRoles.ADMIN === role) {
        return this._loadModelForAdmin(id);
      }

      if (_frontendUtilsUserRoles.DEALER_MANAGER === role) {
        return this._loadModelForManager(id);
      }
    },

    actions: {
      editUser: function editUser(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          var isValid = changeset.get('isValid');

          if (isValid) {
            return changeset.save().then(function () {
              _this.get('flashMessages').success('User successfully added!');
              _this.get('router').transitionTo('settings.companies');
            })['catch'](function () {
              _this.get('flashMessages').danger('Oops! Something went wrong!');
            });
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      if (model.companies) {
        model.companies = model.companies.sortBy('name');
      }

      this._super.apply(this, arguments);
    },

    _loadModelForAdmin: function _loadModelForAdmin(id) {
      var user = this.store.findRecord('user', id);
      var companies = this.store.findAll('company', { reload: true });

      return _rsvp['default'].hash({ user: user, companies: companies });
    },

    _loadModelForManager: function _loadModelForManager(id) {
      var user = this.store.findRecord('user', id);

      return _rsvp['default'].hash({ user: user });
    }
  });
});