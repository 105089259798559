define('frontend/helpers/is-opera', ['exports', 'ember-browser-checker/helpers/is-opera'], function (exports, _emberBrowserCheckerHelpersIsOpera) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsOpera['default'];
    }
  });
  Object.defineProperty(exports, 'isOpera', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsOpera.isOpera;
    }
  });
});