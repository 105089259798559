define('frontend/mixins/table-common', ['exports', 'ember-metal/mixin', 'ember-service/inject', 'ember-light-table'], function (exports, _emberMetalMixin, _emberServiceInject, _emberLightTable) {
  exports['default'] = _emberMetalMixin['default'].create({
    store: (0, _emberServiceInject['default'])(),

    classNames: ['js-data-table'],

    tableHeight: '22rem',
    page: null,
    limit: null,
    dir: null,
    sort: null,

    isLoading: false,
    canLoadMore: true,

    model: null,
    columns: null,
    table: null,

    init: function init() {
      this._super.apply(this, arguments);

      this._initTable();
      this._initTableClassNames();
      this._initSortColumn();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this.get('table').setRows(this.get('model'));
    },

    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          });

          // TODO: This clears the model with no way to fetch new records
          // this.get('model').clear();
        }
      }
    },

    _initTable: function _initTable() {
      this.table = new _emberLightTable['default'](this.get('columns'), this.get('model'));
    },

    _initTableClassNames: function _initTableClassNames() {
      var passedInTableClassNames = this.tableClassNames || '';

      this.tableClassNames = passedInTableClassNames + ' c-data-table';
    },

    _initSortColumn: function _initSortColumn() {
      var table = this.get('table');
      var sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

      if (sortColumn) {
        sortColumn.set('sorted', true);
      }
    }
  });
});