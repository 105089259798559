define('frontend/utils/lead-states', ['exports', 'ember-computed'], function (exports, _emberComputed) {
  exports.cpOpenLeads = cpOpenLeads;
  var ASSIGNED = 'assigned';
  exports.ASSIGNED = ASSIGNED;
  var LOST = 'lost';
  exports.LOST = LOST;
  var NEW = 'new';
  exports.NEW = NEW;
  var OPEN = 'open';
  exports.OPEN = OPEN;
  var WON = 'won';
  exports.WON = WON;
  var PRICE = 'price';
  exports.PRICE = PRICE;
  var WAITING = 'waiting';

  exports.WAITING = WAITING;

  function cpOpenLeads(states) {
    return (0, _emberComputed['default'])(states, function () {
      var loadedLeads = this.get('loadedLeads');
      var leads = loadedLeads || [];
      var filteredLeads = leads.filter(isOpenLead);

      return filteredLeads;
    });
  }

  function isOpenLead(lead) {
    return lead.get('state') === OPEN;
  }

  exports['default'] = {
    ASSIGNED: ASSIGNED,
    LOST: LOST,
    NEW: NEW,
    OPEN: OPEN,
    WON: WON,
    PRICE: PRICE,
    WAITING: WAITING,
    cpOpenLeads: cpOpenLeads
  };
});