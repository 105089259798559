define('frontend/utils/colors', ['exports'], function (exports) {
  var THEME_PRIMARY = '#B71C25';
  exports.THEME_PRIMARY = THEME_PRIMARY;
  var THEME_SECONDARY_1 = '#693FAA';
  exports.THEME_SECONDARY_1 = THEME_SECONDARY_1;
  var THEME_SECONDARY_2 = '#3BB9FF';
  exports.THEME_SECONDARY_2 = THEME_SECONDARY_2;
  var BLACK = '#000000';
  exports.BLACK = BLACK;
  var GRAY = '#DFDFDF';
  exports.GRAY = GRAY;
  var GRAY_LIGHT = '#EEEEEE';
  exports.GRAY_LIGHT = GRAY_LIGHT;
  var GRAY_DARK = '#B6B6B4';

  exports.GRAY_DARK = GRAY_DARK;
  exports['default'] = {
    THEME_PRIMARY: THEME_PRIMARY,
    THEME_SECONDARY_1: THEME_SECONDARY_1,
    THEME_SECONDARY_2: THEME_SECONDARY_2,
    BLACK: BLACK,
    GRAY: GRAY,
    GRAY_LIGHT: GRAY_LIGHT,
    GRAY_DARK: GRAY_DARK
  };
});