define('frontend/models/company', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-computed'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberComputed) {
  exports['default'] = _emberDataModel['default'].extend({
    name: (0, _emberDataAttr['default'])('string'),
    regions: (0, _emberDataAttr['default'])('string'),
    status: (0, _emberDataAttr['default'])('string'),

    leads: (0, _emberDataRelationships.hasMany)('lead'),
    users: (0, _emberDataRelationships.hasMany)('user'),

    displayName: (0, _emberComputed.alias)('name')
  });
});