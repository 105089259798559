define('frontend/utils/user-roles', ['exports'], function (exports) {
  exports.rolesAsArray = rolesAsArray;
  exports.filterRoles = filterRoles;
  var ADMIN = 'admin';
  exports.ADMIN = ADMIN;
  var DEALER_MANAGER = 'dealer_manager';
  exports.DEALER_MANAGER = DEALER_MANAGER;
  var DEALER_SALESPERSON = 'dealer_salesperson';
  exports.DEALER_SALESPERSON = DEALER_SALESPERSON;
  var DISABLED = 'disabled';
  exports.DISABLED = DISABLED;
  var INACTIVE = 'inactive';
  exports.INACTIVE = INACTIVE;
  var INTERNAL_SALESPERSON = 'internal_salesperson';

  exports.INTERNAL_SALESPERSON = INTERNAL_SALESPERSON;
  var ROLES = {
    ADMIN: ADMIN,
    DEALER_MANAGER: DEALER_MANAGER,
    DEALER_SALESPERSON: DEALER_SALESPERSON,
    DISABLED: DISABLED,
    INACTIVE: INACTIVE,
    INTERNAL_SALESPERSON: INTERNAL_SALESPERSON
  };

  function toEnglish(str) {
    return str.replace('_', ' ').split(' ').map(function (str) {
      return str.capitalize();
    }).join(' ');
  }

  function rolesAsArray() {
    var keys = Object.keys(ROLES);
    var roles = keys.reduce(function (acc, role) {
      acc.push({ label: toEnglish(ROLES[role]), value: ROLES[role] });
      return acc;
    }, []);
    return roles;
  }

  function filterRoles(_ref) {
    var value = _ref.value;

    return [ROLES.DEALER_MANAGER, ROLES.DEALER_SALESPERSON].includes(value);
  }

  exports['default'] = ROLES;
});