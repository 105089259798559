define('frontend/utils/activity-event-types', ['exports'], function (exports) {
  var ASSIGNED = 'assigned';
  exports.ASSIGNED = ASSIGNED;
  var CLAIMED = 'claimed';
  exports.CLAIMED = CLAIMED;
  var WON = 'won';
  exports.WON = WON;
  var LOST = 'lost';

  exports.LOST = LOST;
  exports['default'] = {
    ASSIGNED: ASSIGNED,
    CLAIMED: CLAIMED,
    WON: WON,
    LOST: LOST
  };
});