define('frontend/components/edit-lead/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject', 'frontend/utils/lead-next-steps', 'frontend/utils/lead-states'], function (exports, _emberComponent, _emberComputed, _emberServiceInject, _frontendUtilsLeadNextSteps, _frontendUtilsLeadStates) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var fileUploadUrl = function fileUploadUrl(id) {
    return 'leads/' + id;
  };
  var ONE_MEGABYTE = 1000 * 1000;

  // NOTE: lead and nextStepLead are changesets
  // each changeset validates different fields on a lead
  // This is done in order to support the multiple UX fields
  // that are separated by individual "forms". For example:
  // The top edit lead form and the next steps form below it.

  exports['default'] = _emberComponent['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    currentUser: (0, _emberServiceInject['default'])(),

    tagName: 'section',
    classNames: ['page-section'],

    wonLeadState: _frontendUtilsLeadStates.WON,
    lostLeadState: _frontendUtilsLeadStates.LOST,

    nextStepOptions: [].concat(_toConsumableArray(_frontendUtilsLeadNextSteps['default']), [_frontendUtilsLeadStates.WON, _frontendUtilsLeadStates.LOST]),

    dropOptions: (0, _emberComputed['default'])(function () {
      return {
        url: this.actions.uploadSpecification.bind(this),
        // addRemoveLinks: true, // Needs API endpoint for removing file.
        maxFiles: 1,
        acceptedFiles: 'application/pdf'
      };
    }),

    specificationError: null,
    lead: null,

    resetLead: function resetLead() {},
    closeLead: function closeLead() {},
    updateLeadNextStep: function updateLeadNextStep() {},
    requestQuote: function requestQuote() {},

    actions: {
      saveLead: function saveLead() {
        var lead = this.get('lead');
        // lead.set('specifications', null);
        // if (lead.get('specifications')) {
        //   this.onSaveLead(lead);
        // } else if (this.get('specificationError') == false) {
        //
        // if(lead.get('delete_specfication') == "delete" || lead.get('delete_specfication') == "delete"){
        //   lead.set('specifications', "delete");
        // }
        this.onSaveLead(lead);
        // } else {
        //   this.set('specificationError', true);
        // }

        return false;
      },
      delete_pdf: function delete_pdf() {
        var lead, ajax, leadID;
        return regeneratorRuntime.async(function delete_pdf$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              lead = this.get('lead');
              ajax = this.get('ajax');
              leadID = lead.get('id');

              // let quote_deletion_url =  'remove_specification_from_lead/' + leadID;
              // let response = await ajax.request(quote_deletion_url);
              lead.set('specifications', null);
              // console.log(response);
              // document.querySelector('.quote_file_section').innerHTML = "Attached quote has been removed. Reload or save this page to upload a new file.";
              // document.querySelector('.quote_file_section').style.marginTop = "20px";
              // window.location.reload();

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      onActivationDateChange: function onActivationDateChange(_ref) {
        var _ref2 = _slicedToArray(_ref, 1);

        var date = _ref2[0];
        var changeset;
        return regeneratorRuntime.async(function onActivationDateChange$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              changeset = this.get('lead');

              changeset.set('activityDate', date);

              context$1$0.next = 4;
              return regeneratorRuntime.awrap(changeset.validate());

            case 4:

              if (changeset.get('isValid')) {
                changeset.save();
              }

            case 5:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      nextStepSelected: function nextStepSelected(nextStep) {
        var lead = this.get('nextStepLead');
        var closeLead = this.get('closeLead');
        var updateLeadNextStep = this.get('updateLeadNextStep');

        if ([_frontendUtilsLeadStates.WON, _frontendUtilsLeadStates.LOST].includes(nextStep)) {
          return closeLead(nextStep);
        }

        return updateLeadNextStep(lead, nextStep);
      },

      uploadSpecification: function uploadSpecification(files) {

        // NOTE: Cannot use array destructuring here because Safari does not
        // support FileList with the Babel generated _slicedToArray helper.
        // This may be fixed in the latest version of Babel. But not upgrading
        // at the moment.
        /* eslint-disable ember-suave/prefer-destructuring */
        var file = files[0];
        /* eslint-enable ember-suave/prefer-destructuring */

        if (file) {
          var _fileHasErrors2 = this._fileHasErrors(file);

          var _fileHasErrors22 = _slicedToArray(_fileHasErrors2, 2);

          var fileHasErrors = _fileHasErrors22[0];
          var errorMsg = _fileHasErrors22[1];

          var changeset = this.get('lead');

          if (fileHasErrors) {
            changeset.addError('uploadFile', {
              value: file.name,
              validation: errorMsg
            });
            this.set('specificationError', true);
            changeset.validate();
            return;
          }
          this.set('specificationError', false);
          changeset.set('uploadFile', { isValid: true });
          changeset.validate();

          var lead = this.get('lead');
          var property = 'specifications';

          this._upload(lead, property, file, property);
        }
      }
    },

    _upload: function _upload(object, attribute, file, type) {
      var formData, data, processData, contentType, dataType, cache, options, ajax, objectId, url, response, lead;
      return regeneratorRuntime.async(function _upload$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            formData = new FormData();

            formData.append(type, file);

            data = formData;
            processData = false;
            contentType = false;
            dataType = 'json';
            cache = false;
            options = {
              data: data,
              processData: processData,
              contentType: contentType,
              dataType: dataType,
              cache: cache
            };
            ajax = this.get('ajax');
            objectId = object.get('id');
            url = fileUploadUrl(objectId);
            context$1$0.next = 13;
            return regeneratorRuntime.awrap(ajax.patch(url, options));

          case 13:
            response = context$1$0.sent;

            object.set(attribute, response.url);

            lead = this.get('lead');

            lead.set('specifications', response.data.attributes.specifications);

            // window.location.reload();

          case 17:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    _fileHasErrors: function _fileHasErrors(file) {
      var size = file.size;
      var type = file.type;

      var resumeIsTooBig = size > ONE_MEGABYTE;

      if (resumeIsTooBig) {
        var errorMsg = 'Your file is too big.';
        this.get('flashMessages').danger(errorMsg);
        return [true, errorMsg];
      }

      var resumeIsNotPDF = type !== 'application/pdf';

      if (resumeIsNotPDF) {
        var errorMsg = "The file you're uploading needs to be of type PDF.";
        this.get('flashMessages').danger(errorMsg);
        return [true, errorMsg];
      }

      return [false, null];
    }
  });
});