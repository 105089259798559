define('frontend/services/ajax', ['exports', 'ember-computed', 'ember-service/inject', 'ember-ajax/services/ajax', 'frontend/config/environment'], function (exports, _emberComputed, _emberServiceInject, _emberAjaxServicesAjax, _frontendConfigEnvironment) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    session: (0, _emberServiceInject['default'])(),
    namespace: _frontendConfigEnvironment['default'].API_NAMESPACE,
    host: _frontendConfigEnvironment['default'].API_BASE_URL,
    contentType: 'application/vnd.api+json',

    headers: (0, _emberComputed['default'])('session.data.authenticated.token', function () {
      var headers = {};
      var authToken = this.get('session.data.authenticated.token');

      if (authToken) {
        headers.Authorization = 'Bearer ' + authToken;
      }

      headers.Accept = 'application/vnd.api+json';

      return headers;
    })
  });
});