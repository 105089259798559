define('frontend/routes/settings-company', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'rsvp'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _rsvp) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model(_ref) {
      var id = _ref.id;

      var company = this.store.findRecord('company', id);
      var users = this.store.query('user', { 'company_id': id });

      return _rsvp['default'].hash({ company: company, users: users });
    },

    setupController: function setupController(controller, _ref2) {
      var company = _ref2.company;
      var users = _ref2.users;

      this._super.apply(this, arguments);

      controller.set('model', company);
      controller.set('users', users);
    }
  });
});