define('frontend/controllers/lead', ['exports', 'ember-controller', 'frontend/validations/lead', 'frontend/validations/next-step-lead-validations', 'ember-computed'], function (exports, _emberController, _frontendValidationsLead, _frontendValidationsNextStepLeadValidations, _emberComputed) {
  exports['default'] = _emberController['default'].extend({
    LeadValidations: _frontendValidationsLead['default'],
    NextStepLeadValidations: _frontendValidationsNextStepLeadValidations['default'],

    isLeadOpen: (0, _emberComputed['default'])('model.fetchLeadTask.value.state', function () {
      var answer = this.get('model.fetchLeadTask.value.state');
      var closedStates = ['won', 'lost'];

      return !closedStates.includes(answer);
    })
  });
});