define('frontend/abilities/leads', ['exports', 'ember-can', 'ember-computed', 'ember-service/inject', 'frontend/utils/user-roles'], function (exports, _emberCan, _emberComputed, _emberServiceInject, _frontendUtilsUserRoles) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: (0, _emberServiceInject['default'])(),
    user: (0, _emberComputed.readOnly)('currentUser.user'),
    role: (0, _emberComputed.readOnly)('user.role'),

    canEdit: (0, _emberComputed['default'])('role', {
      get: function get() {
        return this.get('role') === _frontendUtilsUserRoles.ADMIN;
      }
    }),

    canQuote: (0, _emberComputed['default'])('role', {
      get: function get() {
        return this.get('role') === _frontendUtilsUserRoles.INTERNAL_SALESPERSON || _frontendUtilsUserRoles.ADMIN;
      }
    }),

    canAssign: (0, _emberComputed['default'])('role', {
      get: function get() {
        return [_frontendUtilsUserRoles.ADMIN, _frontendUtilsUserRoles.DEALER_MANAGER].includes(this.get('role'));
      }
    }),

    canClaim: (0, _emberComputed['default'])('role', {
      get: function get() {
        return [_frontendUtilsUserRoles.INTERNAL_SALESPERSON, _frontendUtilsUserRoles.DEALER_MANAGER, _frontendUtilsUserRoles.DEALER_SALESPERSON].includes(this.get('role'));
      }
    }),

    canRequestQuote: (0, _emberComputed['default'])('role', {
      get: function get() {
        return [_frontendUtilsUserRoles.DEALER_MANAGER, _frontendUtilsUserRoles.DEALER_SALESPERSON].includes(this.get('role'));
      }
    }),

    canAdd: (0, _emberComputed['default'])('role', {
      get: function get() {
        return [_frontendUtilsUserRoles.ADMIN, _frontendUtilsUserRoles.INTERNAL_SALESPERSON].includes(this.get('role'));
      }
    })
  });
});