define("frontend/mirage/routes/analytics", ["exports"], function (exports) {
  exports.pipelineValue = pipelineValue;
  exports.pipelineValueByCompany = pipelineValueByCompany;
  exports.historyByDay = historyByDay;
  exports.historyOfMonth = historyOfMonth;

  function pipelineValue(_ref) {
    var pipelineValues = _ref.pipelineValues;

    // Normally, the API will return 1 object whose values are
    // totalled for all of an admin's manageable companies
    var model = pipelineValues.first();

    return this.serialize(model);
  }

  /* eslint-disable camelcase */

  function pipelineValueByCompany(_ref2, _ref3) {
    var pipelineValues = _ref2.pipelineValues;
    var company_id = _ref3.queryParams.company_id;

    var model = pipelineValues.findBy({ companyId: company_id });

    return this.serialize(model);
  }

  function historyByDay(_ref4, _ref5) {
    var histories = _ref4.histories;
    var day = _ref5.params.day;

    var model = histories.findBy({ daysAgo: Number(day) });

    return this.serialize(model);
  }

  /* eslint-enable camelcase */

  function historyOfMonth(_ref6, _ref7) {
    var histories = _ref6.histories;
    var _ref7$params = _ref7.params;
    var year = _ref7$params.year;
    var month = _ref7$params.month;

    var model = histories.findBy({
      year: Number(year),
      month: Number(month)
    });

    return this.serialize(model);
  }
});