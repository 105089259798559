define('frontend/routes/add-companies', ['exports', 'ember-route', 'rsvp'], function (exports, _emberRoute, _rsvp) {
  exports['default'] = _emberRoute['default'].extend({
    model: function model() {
      return _rsvp['default'].hash({
        company: this.store.createRecord('company')
      });
    },
    actions: {
      resetModel: function resetModel() {
        this.controllerFor(this.routeName).set('model.company', this.store.createRecord('company'));
      }
    }
  });
});