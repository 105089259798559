define('frontend/routes/settings/company', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'rsvp'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _rsvp) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    activate: function activate() {
      this.controllerFor('settings').set('displayNav', false);
    },
    model: function model(_ref) {
      var id = _ref.id;

      var company = this.store.findRecord('company', id);

      return _rsvp['default'].hash({ company: company });
    },

    setupController: function setupController(controller, _ref2) {
      var company = _ref2.company;

      this._super.apply(this, arguments);

      controller.set('model', company);
    },
    redirect: function redirect() {
      this.transitionTo('settings.company.users');
    }
  });
});