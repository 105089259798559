define('frontend/mirage/factories/company', ['exports', 'ember-cli-mirage', 'frontend/mirage/scenarios/leads', 'frontend/utils/user-roles'], function (exports, _emberCliMirage, _frontendMirageScenariosLeads, _frontendUtilsUserRoles) {
  exports['default'] = _emberCliMirage['default'].Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.company.companyName();
    },

    displayName: function displayName() {
      return this.name;
    },

    wirecrafters: (0, _emberCliMirage.trait)({
      name: 'Wirecrafters',

      afterCreate: function afterCreate(company, server) {
        server.create('user', {
          company: company,
          email: 'admin@wirecrafters.com',
          role: _frontendUtilsUserRoles.ADMIN
        });

        server.create('user', 'internalSalesperson', {
          company: company,
          email: 'internal@wirecrafters.com',
          role: _frontendUtilsUserRoles.INTERNAL_SALESPERSON
        });
      }
    }),

    smallDistributor: (0, _emberCliMirage.trait)({
      name: 'Small Distributor',

      afterCreate: function afterCreate(company, server) {
        server.create('user', {
          company: company,
          email: 'manager@small.com',
          role: _frontendUtilsUserRoles.DEALER_MANAGER
        });
      }
    }),

    mediumDistributor: (0, _emberCliMirage.trait)({
      name: 'Medium Distributor',

      afterCreate: function afterCreate(company, server) {
        server.create('user', {
          company: company,
          email: 'manager@medium.com',
          role: _frontendUtilsUserRoles.DEALER_MANAGER
        });

        var user = server.create('user', {
          company: company,
          email: 'salesperson@medium.com',
          role: _frontendUtilsUserRoles.DEALER_SALESPERSON
        });

        var leadSourceId = server.db.leadSources.firstOrCreate({ value: 'website' }).id;

        (0, _frontendMirageScenariosLeads.generateLeads)(server, leadSourceId, 5, company);
        (0, _frontendMirageScenariosLeads.generateLeads)(server, leadSourceId, 2, company, user, true);
        (0, _frontendMirageScenariosLeads.generateLeads)(server, leadSourceId, 2, company, user, false);
      }
    }),

    largeDistributor: (0, _emberCliMirage.trait)({
      name: 'Large Distributor',

      afterCreate: function afterCreate(company, server) {
        server.createList('user', 3, {
          company: company,
          role: _frontendUtilsUserRoles.DEALER_MANAGER
        });

        server.create('user', {
          company: company,
          email: 'manager@large.com',
          role: _frontendUtilsUserRoles.DEALER_MANAGER
        });

        server.createList('user', 8, {
          company: company,
          role: _frontendUtilsUserRoles.DEALER_SALESPERSON
        });

        var externalRep = server.create('user', {
          company: company,
          email: 'salesperson@large.com',
          role: _frontendUtilsUserRoles.DEALER_SALESPERSON
        });

        var leadSourceId = server.db.leadSources.firstOrCreate({ value: 'website' }).id;

        server.createList('lead', 10, 'newState', {
          company: company,
          leadSourceId: leadSourceId,
          externalRep: externalRep
        });
      }
    }),

    withFiveNewLeads: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(company, server) {
        var leads = server.createList('lead', 5, 'newState', { company: company });

        company.leadIds.push(leads.map(function (lead) {
          return lead.id;
        }));
        company.save();
      }
    }),

    withFiveOpenLeads: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(company, server) {
        var leads = server.createList('lead', 5, 'openState', { company: company });

        company.leadIds.push(leads.map(function (lead) {
          return lead.id;
        }));
        company.save();
      }
    }),

    withAnalytics: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(company, server) {
        server.create('pipeline-value', { company: company });
        server.create('history', { daysAgo: 30 });
        server.create('history', { daysAgo: 90 });
        server.create('history', { daysAgo: 365 });
        server.createList('history', 12, { year: 2016 });
        server.createList('history', 12, { year: 2017 });
      }
    }),

    withLeadData: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(company, server) {
        server.createList('lead', 10, 'withSupportingData', { company: company });
      }
    })
  });
});