define('frontend/components/lead-closed-form/component', ['exports', 'ember-component', 'ember-computed', 'ember-metal/get'], function (exports, _emberComponent, _emberComputed, _emberMetalGet) {
  exports['default'] = _emberComponent['default'].extend({
    model: null,

    onClosed: function onClosed() {},
    onCanceled: function onCanceled() {},

    wonLead: (0, _emberComputed.equal)('model.state', 'won'),

    disabled: (0, _emberComputed['default'])('wonLead', 'model.{poNumber,poAmount,closedReason}', function () {
      var wonLead = this.get('wonLead');

      if (wonLead) {
        return !this._isWonLeadValid();
      }

      if (!wonLead) {
        return !this._isLostLeadValid();
      }

      return false;
    }),

    _isWonLeadValid: function _isWonLeadValid() {
      var model = this.get('model');
      var poNumber = (0, _emberMetalGet['default'])(model, 'poNumber');
      var poAmount = (0, _emberMetalGet['default'])(model, 'poAmount');

      if (poNumber && poAmount) {
        return true;
      }

      return false;
    },

    _isLostLeadValid: function _isLostLeadValid() {
      var model = this.get('model');
      var closedReason = (0, _emberMetalGet['default'])(model, 'closedReason');

      if (closedReason) {
        return true;
      }

      return false;
    }
  });
});