define('frontend/routes/settings/index', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberServiceInject) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: (0, _emberServiceInject['default'])(),

    redirect: function redirect() {
      if (this.get('currentUser.user.isAdmin')) {
        this.transitionTo('settings.companies');
        return;
      }

      this.transitionTo('settings.users');
    }
  });
});