define('frontend/components/company-permissions-table/component', ['exports', 'ember-component', 'ember-computed', 'frontend/mixins/table-common'], function (exports, _emberComponent, _emberComputed, _frontendMixinsTableCommon) {
  exports['default'] = _emberComponent['default'].extend(_frontendMixinsTableCommon['default'], {
    columns: (0, _emberComputed['default'])(function () {
      return [{
        label: 'Name',
        valuePath: 'name'
      }, {
        label: 'Status',
        cellComponent: 'toggle-company-status-cell'
      }, {
        label: 'View',
        cellComponent: 'view-company-cell'
      }, {
        label: 'Edit',
        cellComponent: 'edit-company-cell'
      }];
    }),

    editRoute: 'edit-company'
  });
});