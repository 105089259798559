define('frontend/adapters/analyses', ['exports', 'frontend/adapters/application', 'ember-string'], function (exports, _frontendAdaptersApplication, _emberString) {
  exports['default'] = _frontendAdaptersApplication['default'].extend({
    // Override according to the particular type of analysis
    analyticsType: '',

    pathForType: function pathForType() /* modelName */{
      var analyticsType = this.get('analyticsType');

      return 'analytics/' + (0, _emberString.dasherize)(analyticsType);
    }
  });
});