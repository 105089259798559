define('frontend/routes/application', ['exports', 'ember-route', 'ember-service/inject', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _emberRoute, _emberServiceInject, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: (0, _emberServiceInject['default'])(),

    actions: {
      // track the name of the previous route visited by an authenticated user
      willTransition: function willTransition() {
        var session = this.get('session');

        if (session.get('isAuthenticated')) {
          session.set('data.previousRouteName', this.controllerFor('application').currentRouteName);
        }
      },

      executeGlobalSearch: function executeGlobalSearch(input) {
        this.transitionTo('search', { queryParams: { q: input } });
      },

      onLogout: function onLogout() {
        this.get('session').invalidate();
      }
    }
  });
});