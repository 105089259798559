define('frontend/controllers/leads/closed', ['exports', 'ember-controller', 'ember-concurrency', 'ember-service/inject', 'ember-computed'], function (exports, _emberController, _emberConcurrency, _emberServiceInject, _emberComputed) {

  var SEARCH_DEBOUNCE_MS = 250;

  exports['default'] = _emberController['default'].extend({
    search: (0, _emberServiceInject['default'])(),

    closedLeadsSearchQuery: '',
    closedLeadsSearchResults: null,

    hasClosedLeadsSearchQuery: (0, _emberComputed.notEmpty)('closedLeadsSearchQuery'),
    isClosedLeadsSearchComplete: (0, _emberComputed.bool)('searchClosedLeadsTask.lastSuccessful.isSuccessful'),
    shouldShowClosedLeads: (0, _emberComputed.and)('hasClosedLeadsSearchQuery', 'isClosedLeadsSearchComplete'),
    hasNoClosedLeadsSearchResults: (0, _emberComputed.empty)('closedLeadsSearchResults'),
    showEmptyClosedLeadsSearchMessage: (0, _emberComputed.and)('hasNoClosedLeadsSearchResults', 'shouldShowClosedLeads'),

    searchLeads: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(queryOpts) {
      var searchService;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            searchService = this.get('search');
            context$1$0.next = 3;
            return searchService.get('searchEntity').perform('lead', queryOpts);

          case 3:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).cancelOn('deactivate'),

    searchClosedLeadsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(searchQuery) {
      var queryOpts, results;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(SEARCH_DEBOUNCE_MS);

          case 2:
            queryOpts = {
              query: searchQuery,
              'entity-type': 'lead',
              state: 'closed'
            };
            context$1$0.next = 5;
            return this.get('searchLeads').perform(queryOpts);

          case 5:
            results = context$1$0.sent;

            this.set('closedLeadsSearchResults', results);

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable()
  });
});