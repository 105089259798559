define('frontend/components/settings-page/component', ['exports', 'ember-component', 'ember-computed', 'ember-concurrency', 'ember-service/inject', 'frontend/utils/company-statuses'], function (exports, _emberComponent, _emberComputed, _emberConcurrency, _emberServiceInject, _frontendUtilsCompanyStatuses) {

  var SEARCH_INPUT_DEBOUNCE_MS = 275;

  exports['default'] = _emberComponent['default'].extend({
    tagName: ['section'],

    currentUser: (0, _emberServiceInject['default'])(),

    usersSearchQuery: '',
    searchUsers: function searchUsers() {},

    searchUsersTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(searchQuery) {
      var results;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(SEARCH_INPUT_DEBOUNCE_MS);

          case 2:
            context$1$0.next = 4;
            return this.get('searchUsers')(searchQuery);

          case 4:
            results = context$1$0.sent;

            this.set('usersSearchResults', results);

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    loadedCompanies: (0, _emberComputed.readOnly)('loadCompaniesTask.value'),
    loadedDisabledCompanies: (0, _emberComputed.filterBy)('loadedCompanies', 'status', _frontendUtilsCompanyStatuses['default'].DISABLED),
    loadedActiveCompanies: (0, _emberComputed.filterBy)('loadedCompanies', 'status', _frontendUtilsCompanyStatuses['default'].ACTIVE),

    activeCompaniesToShow: (0, _emberComputed.alias)('loadedActiveCompanies'),
    disabledCompaniesToShow: (0, _emberComputed.alias)('loadedDisabledCompanies')
  });
});