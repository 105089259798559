define('frontend/validations/lead', ['exports', 'ember-changeset-validations/validators'], function (exports, _emberChangesetValidationsValidators) {
  exports['default'] = {
    email: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'email' })],
    firstName: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    lastName: (0, _emberChangesetValidationsValidators.validatePresence)(true),
    phone: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateFormat)({ type: 'phone' })],
    zip: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateFormat)({ regex: /^[0-9]{5}(?:-[0-9]{4})?$/ })],
    quoteNumber: [(0, _emberChangesetValidationsValidators.validatePresence)(true)],
    quoteAmount: [(0, _emberChangesetValidationsValidators.validatePresence)(true), (0, _emberChangesetValidationsValidators.validateNumber)(true, { positive: true })]
  };
});