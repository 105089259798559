define('frontend/routes/search', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject', 'ember-concurrency'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberServiceInject, _emberConcurrency) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    searchService: (0, _emberServiceInject['default'])('search'),

    queryParams: {
      q: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel() /* transition */{
      var _paramsFor = this.paramsFor('search');

      var q = _paramsFor.q;

      if (typeof q === 'undefined') {
        this.replaceWith('index');
      }
    },

    model: function model(_ref) {
      var searchQuery = _ref.q;

      return {
        searchTask: this.get('globalSearchTask').perform({ query: searchQuery })
      };
    },

    globalSearchTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(queryOpts) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('searchService.searchGlobally').perform(queryOpts);

          case 2:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).cancelOn('deactivate'),

    actions: {
      viewLead: function viewLead(leadId) {
        this.transitionTo('lead', leadId);
      },

      viewCompany: function viewCompany(companyId) {
        this.transitionTo('edit-company', companyId);
      },

      viewUser: function viewUser(userId) {
        this.transitionTo('edit-user', userId);
      }
    }
  });
});