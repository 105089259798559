define('frontend/mirage/serializers/company', ['exports', 'frontend/mirage/serializers/application'], function (exports, _frontendMirageSerializersApplication) {
  exports['default'] = _frontendMirageSerializersApplication['default'].extend({
    links: function links(company) {
      return {
        'leads': {
          related: '/api/v1/companies/' + company.id + '/leads'
        }
      };
    }
  });
});