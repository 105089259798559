define('frontend/models/note', ['exports', 'ember-data', 'ember-moment/computeds/moment'], function (exports, _emberData, _emberMomentComputedsMoment) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    body: attr('string'),

    insertedAt: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),
    // What the what?!
    updatedAt: attr('date', { defaultValue: function defaultValue() {
        return new Date();
      } }),

    lead: belongsTo('lead'),
    user: belongsTo('user'),

    insertedAtMoment: (0, _emberMomentComputedsMoment['default'])('insertedAt'),
    updatedAtMoment: (0, _emberMomentComputedsMoment['default'])('updatedAt')
  });
});