define('frontend/adapters/analyses/history', ['exports', 'frontend/adapters/analyses'], function (exports, _frontendAdaptersAnalyses) {
  exports['default'] = _frontendAdaptersAnalyses['default'].extend({
    analyticsType: 'history',

    /**
     * Use the `companyId` from our "query" to build a URL instead.
     *
     * @public
     */
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var days = query.days;
      var month = query.month;
      var year = query.year;

      var baseURL = this.buildURL(modelName);

      delete query.days;
      delete query.month;
      delete query.year;

      // If `days` are used, this is a history "by days"
      // Otherwise, we're looking for a history OF a given month.
      if (days) {
        return baseURL + '/by-day/' + days;
      }

      if (month && year) {
        return baseURL + '/of-month/' + year + '/' + month;
      }

      return this._super(query, modelName);
    }
  });
});