define('frontend/components/confirm-password/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-concurrency'], function (exports, _emberComponent, _emberServiceInject, _emberConcurrency) {

  var MESSAGES = {
    SUCCESS: 'Awesome! Try logging in',
    FAILURE: 'Unable to process request. Please try again.'
  };

  exports['default'] = _emberComponent['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    flashMessages: (0, _emberServiceInject['default'])(),

    'on-success': function onSuccess() {},

    confirmNewPassword: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(changeset) {
      var ajax, url, data, method, options, flashMessages;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            ajax = this.get('ajax');
            url = this._getURL();
            data = this._getData(changeset);
            method = 'POST';
            options = { method: method, data: data };
            flashMessages = this.get('flashMessages');
            context$1$0.prev = 6;
            context$1$0.next = 9;
            return ajax.request(url, options);

          case 9:
            flashMessages.success(MESSAGES.SUCCESS);
            this.get('on-success')();
            context$1$0.next = 16;
            break;

          case 13:
            context$1$0.prev = 13;
            context$1$0.t0 = context$1$0['catch'](6);

            flashMessages.danger(MESSAGES.FAILURE);

          case 16:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[6, 13]]);
    })),

    _getURL: function _getURL() {
      return '/users/verify_token';
    },

    /* eslint-disable camelcase */
    _getData: function _getData(changeset) {
      var _changeset$getProperties = changeset.getProperties('token', 'password', 'passwordConfirmation');

      var token = _changeset$getProperties.token;
      var password = _changeset$getProperties.password;
      var password_confirmation = _changeset$getProperties.passwordConfirmation;

      return { token: token, password: password, password_confirmation: password_confirmation };
    }
    /* eslint-enable camelcase */
  });
});