define('frontend/components/assign-leads-table/component', ['exports', 'ember-string', 'ember-component', 'ember-computed', 'frontend/mixins/table-common'], function (exports, _emberString, _emberComponent, _emberComputed, _frontendMixinsTableCommon) {
  exports['default'] = _emberComponent['default'].extend(_frontendMixinsTableCommon['default'], {
    columns: (0, _emberComputed['default'])('type', function () {
      var lead = this.get('lead');
      var isCompany = this.get('type') === 'company';
      var assignLabel = (0, _emberString.capitalize)(this.get('type'));
      var assignValuePath = isCompany ? 'name' : 'fullName';

      var columns = [{
        label: assignLabel,
        valuePath: assignValuePath
      }];

      if (isCompany) {
        columns.pushObjects([{
          label: 'Efficiency',
          width: '50px'
        }]);
      }

      columns.pushObject({
        lead: lead,
        cellComponent: 'assign-lead-link',
        label: 'Action',
        width: '50px'
      });

      return columns;
    })
  });
});