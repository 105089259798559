define('frontend/computeds/user-roles', ['exports', 'ember-computed', 'frontend/utils/user-roles'], function (exports, _emberComputed, _frontendUtilsUserRoles) {
  exports['default'] = computedUserRoles;
  exports.computedUserRolesAsValues = computedUserRolesAsValues;

  function computeRoles(isAdminKey) {
    var isAdmin = this.get(isAdminKey);
    var roles = (0, _frontendUtilsUserRoles.rolesAsArray)();

    if (isAdmin) {
      return roles;
    }

    roles = roles.filter(_frontendUtilsUserRoles.filterRoles);

    return roles;
  }

  function computedUserRoles(isAdminKey) {
    return (0, _emberComputed['default'])(isAdminKey, function () {
      return computeRoles.call(this, isAdminKey);
    });
  }

  function computedUserRolesAsValues(isAdminKey) {
    return (0, _emberComputed['default'])(isAdminKey, function () {
      var roles = computeRoles.call(this, isAdminKey);

      return roles.map(function (role) {
        return role.value;
      });
    });
  }
});