define('frontend/components/user-permissions-table/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject', 'frontend/utils/user-roles', 'frontend/mixins/table-common'], function (exports, _emberComponent, _emberComputed, _emberServiceInject, _frontendUtilsUserRoles, _frontendMixinsTableCommon) {

  var adminColumns = [{
    label: 'Full Name',
    valuePath: 'displayName'
  }, {
    label: 'Email',
    valuePath: 'email'
  }, {
    label: 'Company',
    valuePath: 'company.name'
  }, {
    cellComponent: 'role-dropdown',
    label: 'Role'
  }, {
    label: 'Reporting Access',
    valuePath: 'reportingAccessDisplay'
  }, {
    cellComponent: 'user-permissions-table-actions',
    label: 'Actions'
  }];

  var userColumns = [{
    label: 'Full Name',
    valuePath: 'displayName'
  }, {
    label: 'Email',
    valuePath: 'email'
  }, {
    label: 'Company',
    valuePath: 'company.name'
  }, {
    cellComponent: 'role-dropdown',
    label: 'Role'
  }, {
    cellComponent: 'user-permissions-table-actions',
    label: 'Actions'
  }];

  exports['default'] = _emberComponent['default'].extend(_frontendMixinsTableCommon['default'], {
    currentUser: (0, _emberServiceInject['default'])(),
    user: (0, _emberComputed.readOnly)('currentUser.user'),
    role: (0, _emberComputed.readOnly)('user.role'),
    isAdmin: (0, _emberComputed['default'])('role', {
      get: function get() {
        return this.get('role') === _frontendUtilsUserRoles.ADMIN;
      }
    }),
    columns: (0, _emberComputed['default'])('isAdmin', {
      get: function get() {
        var isAdmin = this.get('isAdmin');
        return isAdmin ? adminColumns : userColumns;
      }
    })
  });
});