define('frontend/serializers/lead', ['exports', 'frontend/serializers/application'], function (exports, _frontendSerializersApplication) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _frontendSerializersApplication['default'].extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      var result = this._super.apply(this, arguments);
      var links = payload.links;

      result.meta = result.meta || {};

      if (links) {
        result.meta.pagination = {
          next: createPageMeta(links.next),
          prev: createPageMeta(links.prev),
          last: createPageMeta(links.last),
          first: createPageMeta(links.first)
        };
      }

      return result;
    }
  });

  function createPageMeta(link) {
    if (!link) {
      return null;
    }

    var meta = {
      page: null,
      size: null
    };

    var _link$slice$split = link.slice(1).split('?');

    var _link$slice$split2 = _slicedToArray(_link$slice$split, 2);

    var /* url */query = _link$slice$split2[1];

    query.split('&').map(function (pairs) {
      return pairs.split('=');
    }).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2);

      var query = _ref2[0];
      var value = _ref2[1];

      if (query.indexOf('page[page]') !== -1) {
        return meta.page = parseInt(value, 10);
      }

      if (query.indexOf('page[page-size]') !== -1) {
        return meta.size = parseInt(value, 10);
      }
    });

    return meta;
  }
});