define('frontend/components/lead-details/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-computed', 'ember-concurrency', 'ember-utils', 'frontend/utils/user-roles', 'frontend/utils/lead-states'], function (exports, _emberComponent, _emberServiceInject, _emberComputed, _emberConcurrency, _emberUtils, _frontendUtilsUserRoles, _frontendUtilsLeadStates) {
  exports['default'] = _emberComponent['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    currentUser: (0, _emberServiceInject['default'])(),
    fetchLeadTask: null,
    fetchActivityLogTask: null,
    fetchNotesTask: null,
    isShowingNoteModal: false,

    user: (0, _emberComputed.readOnly)('currentUser.user'),
    userRole: (0, _emberComputed.readOnly)('currentUser.user.role'),

    lead: (0, _emberComputed.readOnly)('fetchLeadTask.value'),
    isLeadClaimed: (0, _emberComputed.bool)('lead.claimed'),

    leadStates: _frontendUtilsLeadStates['default'],

    canEditLead: (0, _emberComputed['default'])('userRole', 'isLeadClaimed', function () {
      var userRole = this.get('userRole');
      var isLeadClaimed = this.get('isLeadClaimed');
      var isCorrectRole = [_frontendUtilsUserRoles.INTERNAL_SALESPERSON, _frontendUtilsUserRoles.ADMIN].includes(userRole);

      return isLeadClaimed || isCorrectRole;
    }),

    notes: (0, _emberComputed.readOnly)('fetchNotesTask.value'),

    activityLogs: (0, _emberComputed.readOnly)('fetchActivityLogTask.value'),
    hasActivityLogs: (0, _emberComputed.notEmpty)('activityLogs'),

    confirmConvertedToQuotable: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(2000);

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    actions: {
      addNoteToList: function addNoteToList(noteRecord) {
        if ((0, _emberUtils.isPresent)(noteRecord)) {
          this.get('notes').pushObject(noteRecord);
        }
      },
      convertToQuotableLead: function convertToQuotableLead(lead) {
        var _this = this;

        this.get('ajax').request('/leads/' + lead.get('id') + '/price', { method: 'POST' }).then(function () {
          return lead.reload();
        }).then(function () {
          _this.get('confirmConvertedToQuotable').perform();
        });
      }
    }
  });
});