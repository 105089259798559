define('frontend/controllers/analytics', ['exports', 'ember-computed', 'ember-controller', 'ember-service/inject', 'ember-utils', 'frontend/utils/colors', 'frontend/utils/month-names'], function (exports, _emberComputed, _emberController, _emberServiceInject, _emberUtils, _frontendUtilsColors, _frontendUtilsMonthNames) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var CHART_COLOR_SETTINGS = {
    pattern: [_frontendUtilsColors.THEME_PRIMARY, _frontendUtilsColors.THEME_SECONDARY_2, _frontendUtilsColors.GRAY_DARK]
  };

  var PIE_CHART_PADDING = {
    left: 30,
    right: 30,
    top: 0,
    bottom: 0
  };

  exports['default'] = _emberController['default'].extend({
    queryParams: ['company_id'],

    currentUser: (0, _emberServiceInject['default'])('currentUser'),
    mediaService: (0, _emberServiceInject['default'])('media'),

    company_id: null,

    isAdmin: (0, _emberComputed.bool)('currentUser.user.isAdmin'),
    isInternalSalesperson: (0, _emberComputed.bool)('currentUser.user.isInternalSalesperson'),

    companies: (0, _emberComputed.readOnly)('model.companiesTask.value'),
    hasCompanies: (0, _emberComputed.notEmpty)('companies'),

    adminWithCompanies: (0, _emberComputed.and)('isAdmin', 'hasCompanies'),
    internalWithCompanies: (0, _emberComputed.and)('isInternalSalesperson', 'hasCompanies'),
    canSelectCompanies: (0, _emberComputed.or)('adminWithCompanies', 'internalWithCompanies'),

    /**
     * Use month abbreviations for the monthly data chart on narrow screens
     *
     * @public
     */
    useMonthAbbreviations: (0, _emberComputed.bool)('mediaService.isLessThanDesktop'),

    pipelineAnalysis: (0, _emberComputed.readOnly)('model.pipelineValueTask.value'),

    /**
     * Returns the sum of all open leads where lead.quote_amount != nil
     *
     * @public
     */
    pipelineValue: (0, _emberComputed.readOnly)('pipelineAnalysis.quotedValue'),

    /**
     * Returns the counts of all leads where lead.quote_amount != nil
     *
     * @public
     */
    pipelineCount: (0, _emberComputed.readOnly)('pipelineAnalysis.quotedCount'),

    /**
     * Returns the counts of all leads where lead.quote_amount == nil
     *
     * @public
     */
    unquotedCount: (0, _emberComputed.readOnly)('pipelineAnalysis.unquotedCount'),

    /**
     * Returns the result of the averageSalePrice multiplied by the
     * sum of all open leads where lead.quote is present
     *
     * TODO: Ensure that the API returns the proper value
     *
     * @public
     */
    estimatedValue: (0, _emberComputed.readOnly)('pipelineAnalysis.estimatedUnquotedValue'),

    /**
     * Returns the average lead.poAmount where lead.state = won
     *
     * @public
     */
    averageSalePrice: (0, _emberComputed.readOnly)('pipelineAnalysis.quotedAvg'),

    /**
     * Returns the sum of pipelineValue + estimatedValue
     *
     * @public
     */
    totalEstimatedValue: (0, _emberComputed.readOnly)('pipelineAnalysis.totalValue'),

    historyByDays: (0, _emberComputed.readOnly)('model.historyByDaysTask.value'),
    historyOfMonths: (0, _emberComputed.readOnly)('model.historyOfMonthsTask.value'),

    monthlyOpenLeads: (0, _emberComputed.mapBy)('historyOfMonths', 'open'),

    /**
     * Generates the (zero-indexed) number of the current month.
     *
     * @public
     */
    currentMonth: (0, _emberComputed['default'])(function () {
      return new Date().getMonth();
    }).readOnly(),

    monthNames: (0, _emberComputed['default'])('currentMonth', function () {
      return (0, _frontendUtilsMonthNames['default'])({ startingMonth: this.get('currentMonth') });
    }).readOnly(),

    abbreviatedMonthNames: (0, _emberComputed['default'])('currentMonth', function () {
      return (0, _frontendUtilsMonthNames['default'])({ startingMonth: this.get('currentMonth'), abbreviate: true });
    }).readOnly(),

    chartMonthNames: (0, _emberComputed['default'])('useMonthAbbreviations', function () {
      return this.get('useMonthAbbreviations') ? this.get('abbreviatedMonthNames') : this.get('monthNames');
    }),

    /**
     * Data necessary to produce the monthly "open" leads chart.
     *
     * X Axis: Calendar Months
     * Y Axis: Lead Count
     *
     * @public
     */
    dataOfMonths: (0, _emberComputed['default'])('monthlyOpenLeads', 'chartMonthNames', function () {
      var openLeads = this.get('monthlyOpenLeads');
      var chartMonthNames = this.get('chartMonthNames');

      return {
        chartData: {
          columns: [['Open Leads'].concat(_toConsumableArray(openLeads))],
          type: 'line'
        },
        chartAxis: {
          x: {
            type: 'category',
            categories: chartMonthNames
          }
        },
        chartColors: CHART_COLOR_SETTINGS
      };
    }),

    /**
     * Data necessary to produce the donut charts.
     *
     * dataLast30 = won/lost lead data for 30 days
     * dataLast90 = won/lost lead data for 90 days
     * dataLast365 = won/lost lead data for 365 days
     *
     * @public
     */
    dataLast30: (0, _emberComputed['default'])('historyByDays.by30', function () {
      var history = this.get('historyByDays.by30');

      if (!(0, _emberUtils.isEmpty)(history)) {
        return this._makeHistoryByDayChartData(30, history);
      }
    }),

    dataLast90: (0, _emberComputed['default'])('historyByDays.by90', function () {
      var history = this.get('historyByDays.by90');

      if (!(0, _emberUtils.isEmpty)(history)) {
        return this._makeHistoryByDayChartData(90, history);
      }
    }),

    dataLast365: (0, _emberComputed['default'])('historyByDays.by365', function () {
      var history = this.get('historyByDays.by365');

      if (!(0, _emberUtils.isEmpty)(history)) {
        return this._makeHistoryByDayChartData(365, history);
      }
    }),

    _makeHistoryByDayChartData: function _makeHistoryByDayChartData(days, history) {
      var leadsWon = history.get('won');
      var leadsLost = history.get('lost');

      return {
        dayCount: days,
        leadsWon: leadsWon,
        leadsLost: leadsLost,
        chartData: {
          columns: [['won leads', leadsWon], ['lost leads', leadsLost]],
          type: 'donut'
        },
        donutSettings: {
          title: days + ' Days',
          label: { show: false }
        },
        chartPadding: PIE_CHART_PADDING,
        chartColors: CHART_COLOR_SETTINGS
      };
    }
  });
});
/* eslint-disable camelcase */