define('frontend/models/user', ['exports', 'ember-computed', 'ember-data/attr', 'ember-data/model', 'ember-data/relationships', 'frontend/utils/user-roles'], function (exports, _emberComputed, _emberDataAttr, _emberDataModel, _emberDataRelationships, _frontendUtilsUserRoles) {
  exports['default'] = _emberDataModel['default'].extend({
    confirmPassword: (0, _emberDataAttr['default'])('string'),
    efficiencyScore: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
    email: (0, _emberDataAttr['default'])('string'),
    firstName: (0, _emberDataAttr['default'])('string'),
    lastName: (0, _emberDataAttr['default'])('string'),
    password: (0, _emberDataAttr['default'])('string'),
    role: (0, _emberDataAttr['default'])('string'),
    reportingAccess: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
    reportingAccessDisplay: (0, _emberComputed['default'])('role', function () {
      var isAdmin = this.get('isAdmin');
      if (isAdmin) {
        // admin has reporting access by default.
        return null;
      }

      // only internal sales can have reportingAccess set
      var isInternalSalesperson = this.get('isInternalSalesperson');
      return isInternalSalesperson ? this.get('reportingAccess') : null;
    }),
    company: (0, _emberDataRelationships.belongsTo)('company'),

    fullName: (0, _emberComputed['default'])('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    displayName: (0, _emberComputed.alias)('fullName'),

    isAdmin: (0, _emberComputed.equal)('role', _frontendUtilsUserRoles.ADMIN),
    isInternalSalesperson: (0, _emberComputed.equal)('role', _frontendUtilsUserRoles.INTERNAL_SALESPERSON),
    isDealerManager: (0, _emberComputed.equal)('role', _frontendUtilsUserRoles.DEALER_MANAGER),
    isDealerSalesperson: (0, _emberComputed.equal)('role', _frontendUtilsUserRoles.DEALER_SALESPERSON),

    isAdminOrManager: (0, _emberComputed.or)('isAdmin', 'isDealerManager'),
    isDealerManagerOrSalesperson: (0, _emberComputed.or)('isDealerManager', 'isDealerSalesperson'),
    isInternalSalesOrDealerSales: (0, _emberComputed.or)('isInternalSalesperson', 'isDealerSalesperson'),
    // isDealerSalespersonORisDealerManagerORisInternalSalesperson: or('isDealerManager', 'isInternalSalesperson', 'isDealerSalesperson'),

    // Permissions for lead table links app/templates/leads.hbs
    canSeeNewTab: true,
    canSeeQuotableTab: (0, _emberComputed.not)('isInternalSalesperson'),
    canSeeMyTab: (0, _emberComputed.or)('isDealerManager', 'isInternalSalesperson', 'isDealerSalesperson'),
    canSeeAssignedTab: (0, _emberComputed.not)('isInternalSalesOrDealerSales'),
    canSeeWaitingTab: false, // currently removed for ALL
    canSeeOpenTab: (0, _emberComputed.not)('isInternalSalesperson'),
    canSeeClosedTab: true,

    showEfficiencyScore: (0, _emberComputed['default'])('role', function () {
      var role = this.get('role');

      return ![_frontendUtilsUserRoles.ADMIN, _frontendUtilsUserRoles.INTERNAL_SALESPERSON].includes(role);
    })
  });
});