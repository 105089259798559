define('frontend/routes/add-leads', ['exports', 'ember-route', 'rsvp'], function (exports, _emberRoute, _rsvp) {
  exports['default'] = _emberRoute['default'].extend({
    model: function model() {
      return _rsvp['default'].hash({
        lead: this.store.createRecord('lead'),
        leadSources: this.store.findAll('leadSource', { reload: true })
      });
    },
    actions: {
      /**
       * After we add a new lead, we want to start with a new blank lead,
       * in case the user wants to add multiple leads in a row
       * @public
       */
      resetModel: function resetModel() {
        this.controllerFor(this.routeName).set('model.lead', this.store.createRecord('lead'));
      }
    }
  });
});