define('frontend/routes/add-users', ['exports', 'ember-route', 'rsvp'], function (exports, _emberRoute, _rsvp) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _emberRoute['default'].extend({
    model: function model() {
      return _rsvp['default'].hash({
        /*
        NOTE: this.store.createRecord('user') also creates/selects a Company in the process.
        This caused issues where a company would appear to be selected in the form dropdown.
        Set to an empty object to avoid any magical default values when creating a new user.
        */
        user: {},
        companies: this.store.findAll('company', { reload: true })
      });
    },
    setupController: function setupController(controller, model) {
      /*
      NOTE: We are mapping data to mirror other data formats for select-field, companies, user roles, etc.
      Not 100% nessesary but kept events consistent while debugging select-field
      */
      this._super.apply(this, arguments);
      var companies = model.companies.toArray().map(function (company) {
        return { name: company.get('name'), id: company.get('id') };
      });
      model.companies = companies;
    },
    actions: {
      resetModel: function resetModel() {
        // Note: Similar to above - Set user to an empty object instead of this.store.createRecord('user') to avoid a false Company selection
        this.controllerFor(this.routeName).set('model.user', {});
      },
      addUser: function addUser(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          var isValid = changeset.get('isValid');
          // console.log(isValid);
          // console.log(changeset);
          if (isValid) {
            var _changeset$_changes = changeset._changes;
            var firstName = _changeset$_changes.firstName;
            var lastName = _changeset$_changes.lastName;
            var email = _changeset$_changes.email;
            var company = _changeset$_changes.company;
            var role = _changeset$_changes.role;
            var reportingAccess = _changeset$_changes.reportingAccess;

            var companyRecord = _this.store.peekRecord('company', company);

            return _this.store.createRecord('user', {
              firstName: firstName,
              lastName: lastName,
              email: email,
              company: companyRecord,
              role: role,
              reportingAccess: reportingAccess
            }).save().then(function () {
              _this.get('flashMessages').success('User successfully added!');
              _this.send('resetModel');
            })['catch'](function (e) {
              var errors = e.errors;

              var _errors = _slicedToArray(errors, 1);

              var error = _errors[0];
              var email = error.email;

              if (email) {
                _this.get('flashMessages').danger('Email has already been registered');
              } else {
                _this.get('flashMessages').danger('Unkown error creating user');
              }
            });
          }
        });
      }
    }
  });
});