define('frontend/mirage/factories/pipeline-value', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage.Factory.extend({
    quotedAvg: function quotedAvg() {
      return _emberCliMirage.faker.random.number({ min: 2000, max: 500000 });
    },

    quotedValue: function quotedValue() {
      return _emberCliMirage.faker.random.number({ min: 5000, max: 100000 });
    },

    quotedCount: function quotedCount() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 1000 });
    },

    unquotedCount: function unquotedCount() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 1000 });
    },

    estimatedUnquotedValue: function estimatedUnquotedValue() {
      return this.quotedAvg * this.unquotedCount;
    },

    totalValue: function totalValue() {
      return this.estimatedUnquotedValue + this.quotedValue;
    }
  });
});