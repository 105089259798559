define('frontend/routes/index', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject', 'frontend/utils/user-roles'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberServiceInject, _frontendUtilsUserRoles) {
  var _routeFromRole;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: (0, _emberServiceInject['default'])(),

    routeFromRole: (_routeFromRole = {}, _defineProperty(_routeFromRole, _frontendUtilsUserRoles.ADMIN, 'dashboard'), _defineProperty(_routeFromRole, _frontendUtilsUserRoles.INTERNAL_SALESPERSON, 'leads'), _defineProperty(_routeFromRole, _frontendUtilsUserRoles.DEALER_MANAGER, 'dashboard'), _defineProperty(_routeFromRole, _frontendUtilsUserRoles.DEALER_SALESPERSON, 'leads'), _routeFromRole),

    redirect: function redirect() {
      var role = this.get('currentUser.user.role');
      var route = this.routeFromRole[role];
      this.transitionTo(route);
    }

  });
});