define('frontend/utils/company-statuses', ['exports'], function (exports) {
  var ACTIVE = 'active';
  exports.ACTIVE = ACTIVE;
  var DISABLED = 'disabled';

  exports.DISABLED = DISABLED;
  exports['default'] = {
    ACTIVE: ACTIVE,
    DISABLED: DISABLED
  };
});