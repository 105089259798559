define('frontend/mirage/utils/search', ['exports', 'ember-cli-mirage', 'frontend/utils/lead-states'], function (exports, _emberCliMirage, _frontendUtilsLeadStates) {
  exports.handleCompanySearch = handleCompanySearch;
  exports.handleLeadSearch = handleLeadSearch;
  exports.handleUserSearch = handleUserSearch;
  exports.handleGlobalSearch = handleGlobalSearch;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Response = _emberCliMirage['default'].Response;

  function _findCompanyMatches(_ref2, companySchema) {
    var searchQuery = _ref2.query;

    var companyCollection = companySchema.all();
    var toMatch = new RegExp(searchQuery, 'i');

    return companyCollection.filter(function (_ref3) {
      var name = _ref3.name;
      return toMatch.test(name);
    });
  }

  function _findLeadMatches(_ref4, leadSchema) {
    var searchQuery = _ref4.query;
    var stateToMatch = _ref4.state;

    var leadCollection = leadSchema.all();
    var toMatch = new RegExp(searchQuery, 'i');

    return leadCollection.filter(function (_ref5) {
      var id = _ref5.id;
      var firstName = _ref5.firstName;
      var lastName = _ref5.lastName;
      var leadState = _ref5.state;

      var matchesProps = toMatch.test(firstName) || toMatch.test(lastName) || toMatch.test(id);

      if (stateToMatch) {
        var isClosed = [_frontendUtilsLeadStates.WON, _frontendUtilsLeadStates.LOST].includes(leadState);

        return matchesProps && (leadState === stateToMatch || isClosed && stateToMatch === 'closed');
      }

      return matchesProps;
    });
  }

  function _findUserMatches(_ref6, userSchema) {
    var searchQuery = _ref6.query;

    var userCollection = userSchema.all();
    var toMatch = new RegExp(searchQuery, 'i');

    return userCollection.filter(function (_ref7) {
      var firstName = _ref7.firstName;
      var lastName = _ref7.lastName;

      return toMatch.test(firstName) || toMatch.test(lastName);
    });
  }

  /**
   * Helper to replicate the form of the response data returned by
   * our API
   *
   * @private
   */
  function _deserializeGlobalMatches(matchedModels) {
    return matchedModels.map(function (model) {
      return _defineProperty({}, model.modelName, model);
    });
  }

  function handleCompanySearch(queryParams, _ref8) {
    var companies = _ref8.companies;

    return _findCompanyMatches(queryParams, companies);
  }

  function handleLeadSearch(queryParams, _ref9) {
    var leads = _ref9.leads;

    return _findLeadMatches(queryParams, leads);
  }

  function handleUserSearch(queryParams, _ref10) {
    var users = _ref10.users;

    return _findUserMatches(queryParams, users);
  }

  function handleGlobalSearch(queryParams, _ref11) {
    var users = _ref11.users;
    var leads = _ref11.leads;
    var companies = _ref11.companies;

    var _findCompanyMatches2 = _findCompanyMatches(queryParams, companies);

    var companyModels = _findCompanyMatches2.models;

    var _findLeadMatches2 = _findLeadMatches(queryParams, leads);

    var leadModels = _findLeadMatches2.models;

    var _findUserMatches2 = _findUserMatches(queryParams, users);

    var userModels = _findUserMatches2.models;

    var data = _deserializeGlobalMatches([].concat(_toConsumableArray(companyModels), _toConsumableArray(leadModels), _toConsumableArray(userModels)));

    return new Response(201, {}, { data: data });
  }
});