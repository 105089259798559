define('frontend/router', ['exports', 'ember-router', 'frontend/config/environment'], function (exports, _emberRouter, _frontendConfigEnvironment) {

  var Router = _emberRouter['default'].extend({
    location: _frontendConfigEnvironment['default'].locationType,
    rootURL: _frontendConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('add-leads');
    this.route('analytics');
    this.route('assign-lead', { path: '/assign-lead/:lead_id' }, function () {
      this.route('confirm');
    });
    this.route('dashboard');
    this.route('forgot-password');
    this.route('lead', { path: '/lead/:lead_id' });
    this.route('lead-redirect', { path: '/leads/:lead_id' });
    this.route('lead-print', { path: '/lead/:lead_id/print' });
    this.route('leads', function () {
      this.route('closed');
      this.route('new');
      this.route('quotable');
      this.route('my');
      this.route('assigned');
      // this.route('waiting');
      this.route('open');
    });
    this.route('closed-lead', { path: '/closed-lead/:lead_id' });
    this.route('login');
    this.route('print-lead', { path: '/print-lead/:lead_id' });
    this.route('registration');
    this.route('settings', function () {
      this.route('companies', { path: '/companies' });
      this.route('users', { path: '/users' });
      this.route('company', { path: '/company/:id' }, function () {
        this.route('users', { path: '/users' });
        this.route('leads', { path: '/leads' });
      });
    });
    this.route('search');
    this.route('add-companies');
    this.route('edit-company', { path: '/edit-company/:id' });
    this.route('add-users');
    this.route('edit-user', { path: '/edit-user/:id' });
    this.route('recover-user', { path: '/users/password/:token' });
    this.route('dealer-lookup');
  });

  exports['default'] = Router;
});