define('frontend/mixins/searchable-entity-adapter', ['exports', 'ember-metal/mixin', 'frontend/utils/query-param-string'], function (exports, _emberMetalMixin, _frontendUtilsQueryParamString) {
  var assign = Object.assign;

  /**
   * Ember Data adapter mixin that allows us to transform store queries into
   * POSTs to the API's `/search` route. We do this by looking for a "search" query
   * parameter, and treating it as a hash of query-parameter key-value pairs
   *
   * @public
   * @class
   */
  exports['default'] = _emberMetalMixin['default'].create({

    _makeURLForSearchQuery: function _makeURLForSearchQuery(modelName, searchOpts) {
      var baseURL = this.buildURL();
      var qpString = (0, _frontendUtilsQueryParamString['default'])(assign({}, searchOpts, {
        'entity-type': modelName
      }));

      return baseURL + '/search/?' + qpString;
    },

    query: function query(store, type, _query) {
      var searchOpts = _query.search;

      if (searchOpts) {
        var url = this._makeURLForSearchQuery(type.modelName, searchOpts);

        delete _query.search;

        return this.ajax(url, 'POST', { data: _query });
      }

      return this._super(store, type, _query);
    }
  });
});