define('frontend/components/registration-form/component', ['exports', 'ember-component'], function (exports, _emberComponent) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _emberComponent['default'].extend({
    registrationComplete: false,
    alreadyTaken: false,
    error: false,
    actions: {
      register: function register(changeset) {
        var _this = this;

        var userProvidedEmail = changeset.get('email');

        if (userProvidedEmail) {
          changeset.set('email', userProvidedEmail.toLowerCase());
        }

        return changeset.validate().then(function () {
          var isValid = changeset.get('isValid');
          if (isValid) {
            return changeset.save().then(function () {
              _this.set('registrationComplete', true);
            })['catch'](function (e) {
              var errors = e.errors;

              var _errors = _slicedToArray(errors, 1);

              var error = _errors[0];
              var email = error.email;

              if (email) {
                _this.set('alreadyTaken', true);
              } else {
                _this.set('error', true);
              }
            });
          }
        });
      }
    }
  });
});