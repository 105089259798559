define('frontend/components/assign-lead/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    zoom: 6,

    lat: (0, _emberComputed.readOnly)('model.lead.lat'),
    lng: (0, _emberComputed.readOnly)('model.lead.lng'),
    dealerReferral: (0, _emberComputed.readOnly)('model.lead.dealerReferral'),

    zipCenter: (0, _emberComputed['default'])('model.lead', function () {
      return [this.get('model.lead.lat'), this.get('model.lead.lng')];
    })
  });
});