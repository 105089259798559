define('frontend/routes/dashboard', ['exports', 'ember-route', 'ember-service/inject', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/activity-log-route'], function (exports, _emberRoute, _emberServiceInject, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _frontendMixinsActivityLogRoute) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _frontendMixinsActivityLogRoute['default'], {
    currentUser: (0, _emberServiceInject['default'])(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var canView = this.get('currentUser.user.isAdmin') || this.get('currentUser.user.isDealerManager');
      if (!canView) {
        this.replaceWith('index');
      }
    },

    model: function model() {
      return {
        fetchActivityLogTask: this.get('fetchActivityLogTask').perform()
      };
    },

    actions: {
      changeActivityLogEventType: function changeActivityLogEventType(eventType) {
        var newTask = this.get('fetchActivityLogTask').perform({ eventType: eventType });

        this.set('currentModel.fetchActivityLogTask', newTask);
      }
    }
  });
});