define('frontend/components/lead-list-line-item/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: 'tr',

    lead: null,

    referenceNumber: (0, _emberComputed.readOnly)('lead.referenceNumber'),
    insertedAt: (0, _emberComputed.readOnly)('lead.insertedAt'),
    leadId: (0, _emberComputed.readOnly)('lead.id'),
    displayName: (0, _emberComputed.readOnly)('lead.displayName'),
    zipCode: (0, _emberComputed.readOnly)('lead.zip')
  });
});