define('frontend/initializers/ember-links-with-follower', ['exports', 'frontend/config/environment', 'ember-links-with-follower/configuration'], function (exports, _frontendConfigEnvironment, _emberLinksWithFollowerConfiguration) {
  exports.initialize = initialize;

  function initialize() {
    var config = _frontendConfigEnvironment['default']['ember-links-with-follower'] || {};
    _emberLinksWithFollowerConfiguration['default'].load(config);
  }

  exports['default'] = {
    name: 'ember-links-with-follower',
    initialize: initialize
  };
});