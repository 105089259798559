define('frontend/routes/leads/new', ['exports', 'ember-route', 'frontend/routes/leads/shared/actions'], function (exports, _emberRoute, _frontendRoutesLeadsSharedActions) {
  exports['default'] = _emberRoute['default'].extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var number = _ref.page;
      var size = _ref.size;

      var state = 'new';

      return this.store.query('lead', {
        state: state,
        page: {
          page: number,
          'page_size': size
        }
      });
    },

    actions: {
      viewLead: _frontendRoutesLeadsSharedActions.viewLead
    }
  });
});