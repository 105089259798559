define('frontend/components/nav-menu/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject'], function (exports, _emberComponent, _emberComputed, _emberServiceInject) {
  exports['default'] = _emberComponent['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),
    session: (0, _emberServiceInject['default'])(),

    onLogout: function onLogout() {},

    isAuthenticated: (0, _emberComputed.alias)('session.isAuthenticated'),
    isAdmin: (0, _emberComputed.bool)('currentUser.user.isAdmin'),
    hasReportingAccess: (0, _emberComputed['default'])('current.user.isAdmin', 'currentUser.user.reportingAccess', function () {
      var admin = this.get('currentUser.user.isAdmin');
      var reportingAccess = this.get('currentUser.user.reportingAccess');

      return admin || reportingAccess;
    }),

    leadsNavText: (0, _emberComputed['default'])('currentUser.user.role', function () {
      return this.get('currentUser.user.isAdmin') ? 'Distribute Leads' : 'Leads';
    }),

    actions: {
      onGlobalNavInput: function onGlobalNavInput(input) {
        this.get('onSearchInput')(input);
      }
    }
  });
});