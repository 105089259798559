define('frontend/mirage/scenarios/leads', ['exports', 'ember-platform'], function (exports, _emberPlatform) {
  /**
   * Generate leads for mirage
   * @param server {server} The mirage server
   * @param leadSourceId {leadSource.id} The id of the source the lead came from
   * @param quantity {number} Number of leads to generate
   * @param company {company} The company the lead is assigned to
   * @param externalRep {user} The rep the lead is assigned to
   * @param claimed {boolean} Whether the lead is claimed or not
   * @public
   */
  function generateLeads(server, leadSourceId, quantity, company, externalRep, claimed) {
    for (var i = 0; i < quantity; i++) {
      var leadObj = {
        leadSourceId: leadSourceId
      };

      if (company) {
        (0, _emberPlatform.assign)(leadObj, {
          company: company
        });
      }

      if (externalRep) {
        (0, _emberPlatform.assign)(leadObj, {
          externalRepId: externalRep.id
        });
      }

      if (claimed) {
        (0, _emberPlatform.assign)(leadObj, {
          claimed: claimed
        });
      }

      server.create('lead', leadObj);
    }
  }

  exports.generateLeads = generateLeads;
});