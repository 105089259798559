define('frontend/components/company-form/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject'], function (exports, _emberComponent, _emberComputed, _emberServiceInject) {
  exports['default'] = _emberComponent['default'].extend({
    flashMessages: (0, _emberServiceInject['default'])(),
    router: (0, _emberServiceInject['default'])('-routing'),

    submitVerb: (0, _emberComputed['default'])('changeset.isNew', function () {
      return this.get('changeset.isNew') ? 'Add' : 'Update';
    }),

    resetModel: function resetModel() {},

    actions: {
      addCompany: function addCompany(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          var isValid = changeset.get('isValid');

          if (isValid) {
            return changeset.save().then(function () {
              _this.get('flashMessages').success('Company successfully added!');
              _this.resetModel();
              _this.get('router').transitionTo('settings');
            });
          }
        });
      }
    }
  });
});