define('frontend/authenticators/custom', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'ember-utils', 'rsvp', 'ember-service/inject'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _emberUtils, _rsvp, _emberServiceInject) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    store: (0, _emberServiceInject['default'])(),
    currentUser: (0, _emberServiceInject['default'])(),

    authenticate: function authenticate(email, password) {
      var _this = this;

      var data = JSON.stringify({
        email: email,
        password: password
      });

      var requestOptions = {
        url: '/login',
        type: 'POST',
        data: data
      };

      var token = undefined;
      return this.get('ajax').request('/login', requestOptions).then(function (response) {
        token = response.token;
        return _this.loadAndSetCurrentUser(token);
      }).then(function () {
        return { token: token };
      });
    },

    restore: function restore(data) {
      if (!(0, _emberUtils.isEmpty)(data.token)) {
        return this.loadAndSetCurrentUser(data.token).then(function () {
          return data;
        });
      } else {
        return _rsvp['default'].reject('Could not restore');
      }
    },

    loadAndSetCurrentUser: function loadAndSetCurrentUser(token) {
      var _this2 = this;

      var userAdapter = getOwner(this).lookup('adapter:user');
      var meUrl = userAdapter.urlForQueryRecord({ me: true }, 'user');
      return this.get('ajax').request(meUrl, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (userPayload) {
        _this2.get('store').pushPayload('user', userPayload);
        var userRecord = _this2.get('store').peekRecord('user', userPayload.data.id);
        _this2.set('currentUser.user', userRecord);
      });
    }

  });
});