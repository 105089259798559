define('frontend/mirage/factories/lead', ['exports', 'ember-cli-mirage', 'frontend/utils/lead-states'], function (exports, _emberCliMirage, _frontendUtilsLeadStates) {
  var _faker$list;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var values = Object.values;
  exports['default'] = _emberCliMirage.Factory.extend({
    externalRepId: null,

    referenceNumber: function referenceNumber(i) {
      return i;
    },

    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },

    claimed: function claimed() {
      return false;
    },

    email: function email() {
      return this.firstName.toLowerCase() + '@email.com';
    },

    insertedAt: function insertedAt() {
      return _emberCliMirage.faker.date.recent();
    },

    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },

    lat: function lat() {
      return 1;
    },

    lng: function lng() {
      return 1;
    },

    localDealer: function localDealer() {
      return _emberCliMirage.faker.random.boolean();
    },

    message: function message() {
      return _emberCliMirage.faker.lorem.paragraph();
    },

    phone: function phone() {
      return _emberCliMirage.faker.phone.phoneNumber();
    },

    zip: function zip() {
      return _emberCliMirage.faker.address.zipCode();
    },

    specifications: function specifications() {
      return _emberCliMirage.faker.internet.url();
    },

    state: (_faker$list = _emberCliMirage.faker.list).cycle.apply(_faker$list, _toConsumableArray(values(_frontendUtilsLeadStates['default']))),

    newState: (0, _emberCliMirage.trait)({
      state: _frontendUtilsLeadStates.NEW
    }),

    openState: (0, _emberCliMirage.trait)({
      state: _frontendUtilsLeadStates.OPEN
    }),

    withSupportingData: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(lead, server) {
        var company = lead.company;

        var user = server.schema.users.findBy({ companyId: company.id });

        server.createList('activity-log', 5, { lead: lead, company: company, user: user });
        server.createList('note', 5, { lead: lead });
      }
    }),

    withNotes: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(lead, server) {
        server.createList('note', 5, { lead: lead });
      }
    })
  });
});