define('frontend/helpers/is-safari', ['exports', 'ember-browser-checker/helpers/is-safari'], function (exports, _emberBrowserCheckerHelpersIsSafari) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsSafari['default'];
    }
  });
  Object.defineProperty(exports, 'isSafari', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsSafari.isSafari;
    }
  });
});