define('frontend/authorizers/custom', ['exports', 'ember-simple-auth/authorizers/base', 'ember-service/inject'], function (exports, _emberSimpleAuthAuthorizersBase, _emberServiceInject) {
  exports['default'] = _emberSimpleAuthAuthorizersBase['default'].extend({
    session: (0, _emberServiceInject['default'])(),

    authorize: function authorize(sessionData, block) {
      var token = sessionData.token;

      if (this.get('session.isAuthenticated') && token) {
        block('Authorization', 'Bearer ' + token);
      }
    }
  });
});