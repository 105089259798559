define('frontend/components/pagination-nav/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    pagination: null,
    routeName: null,

    notFirst: (0, _emberComputed.not)('pagination.first'),
    notPrev: (0, _emberComputed.not)('pagination.prev'),
    notNext: (0, _emberComputed.not)('pagination.next'),
    notLast: (0, _emberComputed.not)('pagination.last')
  });
});