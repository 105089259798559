define('frontend/components/lead-note-list/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    classNames: ['c-list-card'],
    notes: null,

    addNote: function addNote() {},

    hasNotes: (0, _emberComputed.notEmpty)('notes'),
    noteCount: (0, _emberComputed.readOnly)('notes.length')
  });
});