define('frontend/routes/assign-lead/confirm', ['exports', 'ember-route', 'rsvp'], function (exports, _emberRoute, _rsvp) {
  exports['default'] = _emberRoute['default'].extend({
    queryParams: {
      id: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      }
    },
    /**
     * Gets all the relevant model data for assigning leads
     * @param params These params will include type and id
     * @param params.id {string} The id for the model
     * @param params.type {string} The type, either "company" or "user"
     * @returns {RSVP.hash} The hash of all the various things
     * @private
     */
    model: function model(params) {
      return _rsvp['default'].hash({
        assignTo: this.store.find(params.type, params.id),
        assignToType: params.type,
        lead: this.modelFor('assign-lead').lead,
        options: this.modelFor('assign-lead').assignToOptions
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('assign-lead');
      },
      confirm: function confirm(model) {
        var _this = this;

        var assignTo = model.assignTo;
        var assignToType = model.assignToType;
        var lead = model.lead;

        if (assignToType === 'company') {
          lead.set('company', assignTo);
        } else {
          lead.set('externalRep', assignTo);
        }

        lead.save().then(function () {
          _this.transitionTo('leads');
        });
      }
    }
  });
});