define('frontend/components/edit-user-form/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject', 'frontend/computeds/user-roles'], function (exports, _emberComponent, _emberComputed, _emberServiceInject, _frontendComputedsUserRoles) {
  exports['default'] = _emberComponent['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),
    flashMessages: (0, _emberServiceInject['default'])(),

    isAdmin: (0, _emberComputed.bool)('currentUser.user.isAdmin'),
    companies: null,
    submitCopy: 'Add User',

    roles: (0, _frontendComputedsUserRoles['default'])('currentUser.user.isAdmin'),

    saveUser: null,

    actions: {
      onCompanyChange: function onCompanyChange(companyId) {
        var company = this.get('companies').find(function (company) {
          return company.id === companyId;
        });

        this.get('changeset').set('company', company);
      }
    }
  });
});