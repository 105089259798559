define('frontend/mirage/factories/user', ['exports', 'ember-cli-mirage', 'frontend/utils/user-roles'], function (exports, _emberCliMirage, _frontendUtilsUserRoles) {
  var _faker$list;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var values = Object.values;
  exports['default'] = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },

    address: function address() {
      return _emberCliMirage.faker.address.streetAddress();
    },

    city: function city() {
      return _emberCliMirage.faker.address.city();
    },

    email: function email() {
      return this.firstName.toLowerCase() + '@email.com';
    },

    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },

    password: function password() {
      return 'test1234';
    },

    confirmPassword: function confirmPassword() {
      return this.password;
    },

    role: (_faker$list = _emberCliMirage.faker.list).cycle.apply(_faker$list, _toConsumableArray(values(_frontendUtilsUserRoles['default']))),

    state: function state() {
      return _emberCliMirage.faker.address.state();
    },

    zip: function zip() {
      return _emberCliMirage.faker.address.zipCode();
    },

    efficiencyScore: function efficiencyScore() {
      return _emberCliMirage.faker.random.number({ min: 0, max: 10 });
    },

    admin: (0, _emberCliMirage.trait)({
      role: _frontendUtilsUserRoles.ADMIN
    }),

    dealerManager: (0, _emberCliMirage.trait)({
      role: _frontendUtilsUserRoles.DEALER_MANAGER
    }),

    dealerSalesperson: (0, _emberCliMirage.trait)({
      role: _frontendUtilsUserRoles.DEALER_SALESPERSON
    }),

    disabled: (0, _emberCliMirage.trait)({
      role: _frontendUtilsUserRoles.DISABLED
    }),

    inactive: (0, _emberCliMirage.trait)({
      role: _frontendUtilsUserRoles.INACTIVE
    }),

    internalSalesperson: (0, _emberCliMirage.trait)({
      role: _frontendUtilsUserRoles.INTERNAL_SALESPERSON
    }),

    withCompany: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(user, server) {
        var company = server.create('company');
        user.company = company;
        user.save();
      }
    }),

    fullName: function fullName() {
      return this.firstName + ' ' + this.lastName;
    },

    displayName: function displayName() {
      return this.fullName;
    }
  });
});