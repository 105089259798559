define('frontend/models/analyses/pipeline-value', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var Model = _emberData['default'].Model;
  exports['default'] = Model.extend({
    quotedAvg: attr('number', { defaultValue: 0 }),
    quotedValue: attr('number', { defaultValue: 0 }),
    quotedCount: attr('number', { defaultValue: 0 }),

    // The API will also compute these for us using the raw values above
    unquotedCount: attr('number', { defaultValue: 0 }),
    estimatedUnquotedValue: attr('number', { defaultValue: 0 }),
    totalValue: attr('number', { defaultValue: 0 })
  });
});