define('frontend/routes/settings/company/leads', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'rsvp'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _rsvp) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    activate: function activate() {
      this.controllerFor('settings.company').set('displayNav', false);
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var number = _ref.page;
      var size = _ref.size;

      var parentParams = this.paramsFor('settings.company');
      var id = parentParams.id;

      var company = this.store.findRecord('company', id);
      var leads = this.store.query('lead', {
        'company_id': id,
        page: {
          page: number,
          'page_size': size
        }
      });

      return _rsvp['default'].hash({ company: company, leads: leads });
    },

    setupController: function setupController(controller, _ref2) {
      var company = _ref2.company;
      var leads = _ref2.leads;

      this._super.apply(this, arguments);

      controller.set('company', company);
      controller.set('leads', leads);
    }
  });
});