define('frontend/routes/assign-lead', ['exports', 'ember-computed', 'ember-route', 'rsvp', 'ember-service/inject', 'frontend/utils/user-roles'], function (exports, _emberComputed, _emberRoute, _rsvp, _emberServiceInject, _frontendUtilsUserRoles) {
  exports['default'] = _emberRoute['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),
    user: (0, _emberComputed.readOnly)('currentUser.user'),

    model: function model(params) {
      var _this = this;

      var assignToOptions = undefined,
          assignToType = undefined;

      if (this.get('user.isAdmin')) {
        assignToOptions = this.store.query('company', { 'lead-id': params.lead_id }).then(function (companies) {
          return companies.rejectBy('id', _this.get('user.company.id'));
        });

        assignToType = 'company';
      } else if (this.get('user.isDealerManager')) {
        assignToOptions = this.store.query('user', {
          companyId: this.get('user.company.id'),
          role: _frontendUtilsUserRoles.DEALER_SALESPERSON
        });

        assignToType = 'user';
      }

      return _rsvp['default'].hash({
        assignToOptions: assignToOptions,
        assignToType: assignToType,
        lead: this.store.findRecord('lead', params.lead_id)
      });
    }
  });
});