define('frontend/components/companies-search-results-table/component', ['exports', 'ember-component', 'ember-computed', 'frontend/mixins/table-common'], function (exports, _emberComponent, _emberComputed, _frontendMixinsTableCommon) {
  exports['default'] = _emberComponent['default'].extend(_frontendMixinsTableCommon['default'], {
    columns: (0, _emberComputed['default'])(function () {
      var base = [{
        label: 'Company #',
        valuePath: 'id'
      }, {
        label: 'Name',
        valuePath: 'display-name'
      }];

      return base;
    }),

    actions: {
      rowClicked: function rowClicked(row) {
        this.selectRow(row.get('id'));
      }
    }
  });
});