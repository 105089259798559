define('frontend/components/role-dropdown/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-computed', 'frontend/computeds/user-roles'], function (exports, _emberComponent, _emberServiceInject, _emberComputed, _frontendComputedsUserRoles) {
  exports['default'] = _emberComponent['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),

    user: (0, _emberComputed.readOnly)('row.content'),

    roles: (0, _frontendComputedsUserRoles.computedUserRolesAsValues)('currentUser.user.isAdmin'),

    actions: {
      saveRoleChanges: function saveRoleChanges(role) {
        var user = this.get('user');

        user.set('role', role);
        user.save();
      }
    }
  });
});