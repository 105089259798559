define('frontend/mirage/factories/note', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = _emberCliMirage.Factory.extend({
    body: function body() {
      return _emberCliMirage.faker.lorem.paragraph();
    },

    insertedAt: function insertedAt() {
      return _emberCliMirage.faker.date.recent();
    },

    updatedAt: function updatedAt() {
      return _emberCliMirage.faker.date.recent();
    }
  });
});