define('frontend/components/activity-log-item/component', ['exports', 'ember-component', 'ember-computed', 'ember-moment/computeds/moment', 'ember-moment/computeds/format', 'ember-service/inject', 'frontend/utils/activity-event-types'], function (exports, _emberComponent, _emberComputed, _emberMomentComputedsMoment, _emberMomentComputedsFormat, _emberServiceInject, _frontendUtilsActivityEventTypes) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _emberComponent['default'].extend({
    tagName: 'li',
    classNames: ['c-list-item'],

    store: (0, _emberServiceInject['default'])(),

    logEntry: null,

    leadId: (0, _emberComputed.readOnly)('logEntry.leadId'),
    actor: (0, _emberComputed.readOnly)('logEntry.actor'),
    target: (0, _emberComputed.readOnly)('logEntry.target'),
    detail: (0, _emberComputed.readOnly)('logEntry.detail'),
    eventType: (0, _emberComputed.readOnly)('logEntry.eventType'),

    lead: (0, _emberComputed['default'])('logEntry.leadId', function () {
      if (this.get('logEntry.leadId')) {
        return this.get('store').findRecord('lead', this.get('logEntry.leadId'));
      }
    }),

    descriptionIconName: (0, _emberComputed['default'])('eventType', function () {
      var _ASSIGNED$CLAIMED$completed$LOST$WON$eventType;

      var eventType = this.get('eventType') || 'completed';

      return (_ASSIGNED$CLAIMED$completed$LOST$WON$eventType = {}, _defineProperty(_ASSIGNED$CLAIMED$completed$LOST$WON$eventType, _frontendUtilsActivityEventTypes.ASSIGNED, 'assigned'), _defineProperty(_ASSIGNED$CLAIMED$completed$LOST$WON$eventType, _frontendUtilsActivityEventTypes.CLAIMED, 'assigned'), _defineProperty(_ASSIGNED$CLAIMED$completed$LOST$WON$eventType, 'completed', 'completed'), _defineProperty(_ASSIGNED$CLAIMED$completed$LOST$WON$eventType, _frontendUtilsActivityEventTypes.LOST, 'lost'), _defineProperty(_ASSIGNED$CLAIMED$completed$LOST$WON$eventType, _frontendUtilsActivityEventTypes.WON, 'trophy'), _ASSIGNED$CLAIMED$completed$LOST$WON$eventType)[eventType];
    }),

    eventDescriptionStart: (0, _emberComputed['default'])('eventType', 'actor', function () {
      var eventType = this.get('eventType');
      var actor = this.get('logEntry.actor');

      return actor + ' ' + eventType;
    }),

    eventDescriptionEnd: (0, _emberComputed['default'])('eventType', 'target', function () {
      var eventType = this.get('eventType');
      var target = this.get('target');

      return eventType === _frontendUtilsActivityEventTypes.ASSIGNED ? 'to ' + target : '';
    }),

    detailDescription: (0, _emberComputed['default'])('eventType', 'detail', function () {
      var eventType = this.get('eventType');
      var detail = this.get('detail');

      return [_frontendUtilsActivityEventTypes.WON, _frontendUtilsActivityEventTypes.LOST].includes(eventType) ? 'Reason: ' + detail : detail;
    }),

    insertedAtMoment: (0, _emberMomentComputedsMoment['default'])('logEntry.insertedAt'),

    insertedAtTime: (0, _emberMomentComputedsFormat['default'])('insertedAtMoment', 'hh:mma'),
    insertedAtDate: (0, _emberMomentComputedsFormat['default'])('insertedAtMoment', 'MM-DD-YYYY')
  });
});