define('frontend/routes/closed-lead', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject', 'frontend/routes/leads/shared/actions'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberServiceInject, _frontendRoutesLeadsSharedActions) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    session: (0, _emberServiceInject['default'])('session'),

    actions: {
      viewLead: _frontendRoutesLeadsSharedActions.viewLead,

      cancel: function cancel(lead) {
        var previousRouteName = this.get('session.data.previousRouteName');
        var replaceWithArgs = previousRouteName === 'lead' ? [previousRouteName, lead.id] : [previousRouteName];

        lead.rollbackAttributes();
        this.replaceWith.apply(this, replaceWithArgs);
      },

      onClosed: function onClosed(lead) {
        lead.save();
        this.replaceWith('leads');
      }
    }
  });
});