define('frontend/helpers/-inject-layout', ['exports', 'flexi-layouts/helpers/-inject-layout'], function (exports, _flexiLayoutsHelpersInjectLayout) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _flexiLayoutsHelpersInjectLayout['default'];
    }
  });
  Object.defineProperty(exports, 'injectLayout', {
    enumerable: true,
    get: function get() {
      return _flexiLayoutsHelpersInjectLayout.injectLayout;
    }
  });
});