define('frontend/helpers/is-firefox', ['exports', 'ember-browser-checker/helpers/is-firefox'], function (exports, _emberBrowserCheckerHelpersIsFirefox) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsFirefox['default'];
    }
  });
  Object.defineProperty(exports, 'isFirefox', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsFirefox.isFirefox;
    }
  });
});