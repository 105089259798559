define('frontend/adapters/user', ['exports', 'frontend/adapters/application', 'frontend/mixins/searchable-entity-adapter'], function (exports, _frontendAdaptersApplication, _frontendMixinsSearchableEntityAdapter) {
  exports['default'] = _frontendAdaptersApplication['default'].extend(_frontendMixinsSearchableEntityAdapter['default'], {
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var url = this._super(query, modelName);

      if (query.me) {
        delete query.me;
        return url + '/me';
      }

      return url;
    }
  });
});