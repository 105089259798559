define('frontend/mirage/scenarios/default', ['exports'], function (exports) {
  exports['default'] = function (server) {
    server.createList('lead', 5);

    // email: admin@wirecrafters.com
    // password: test1234
    server.create('company', 'wirecrafters', 'withAnalytics', 'withLeadData');

    // email: manager@small.com
    // password: test1234
    server.create('company', 'smallDistributor', 'withAnalytics');

    // email: manager@medium.com
    // password: test1234

    // email: salesperson@medium.com
    // password: test1234
    server.create('company', 'mediumDistributor', 'withAnalytics');

    // email: manager@large.com
    // password: test1234

    // email: salesperson@large.com
    // password: test1234
    server.create('company', 'largeDistributor', 'withAnalytics');

    server.createList('user', 5, 'inactive');
    server.createList('user', 5, 'disabled');
  };
});