define('frontend/mirage/config', ['exports', 'ember-cli-mirage', 'frontend/mirage/utils/search', 'frontend/mirage/routes/analytics'], function (exports, _emberCliMirage, _frontendMirageUtilsSearch, _frontendMirageRoutesAnalytics) {

  var JSON_API_HEADERS = {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json'
  };

  exports['default'] = function () {
    // These comments are here to help you get started. Feel free to delete them.

    /*
     Config (with defaults).
      Note: these only affect routes defined *after* them!
     */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing
    // this.logging = true;
    this.namespace = '/api/v1';

    // Activity Logs

    this.get('/activity-logs/', function (_ref, _ref2) {
      var activityLogs = _ref.activityLogs;
      var _ref2$queryParams = _ref2.queryParams;
      var leadId = _ref2$queryParams.leadId;
      var eventType = _ref2$queryParams.eventType;
      var limit = _ref2$queryParams.limit;

      // Are we finding logs for all leads, or logs for a specific lead?
      if (leadId) {
        var record = activityLogs.findBy({ leadId: leadId });

        return this.serialize(record);
      }

      var collection = activityLogs.all();

      if (eventType) {
        collection = collection.filter(function (model) {
          return model.eventType() === eventType;
        });
      }

      if (limit) {
        collection = collection.slice(0, limit);
      }

      return this.serialize(collection);
    });

    // Analytics

    this.get('/analytics/pipeline-value/', _frontendMirageRoutesAnalytics.pipelineValue);
    this.get('/analytics/pipeline-value/:companyId', _frontendMirageRoutesAnalytics.pipelineValueByCompany);
    this.get('/analytics/history/by-day/:day', _frontendMirageRoutesAnalytics.historyByDay);
    this.get('/analytics/history/of-month/:year/:month', _frontendMirageRoutesAnalytics.historyOfMonth);

    // Companies

    this.get('/companies');
    this.post('/companies');
    this.get('/companies/:id');
    this.patch('/companies/:id');
    this.get('/companies/:id/leads');

    // Leads

    // NOTE: Override this in your tests. Return the leads you expect
    // this.get('/leads');

    this.get('/leads/:id');
    this.patch('/leads/:id');
    this.post('/leads');

    this.get('lead-sources');
    this.get('lead-sources/:id');

    // Login

    this.post('/login', function (_ref3, request) {
      var users = _ref3.users;

      var _JSON$parse = JSON.parse(request.requestBody);

      var email = _JSON$parse.email;
      var password = _JSON$parse.password;

      var user = users.findBy({ email: email, password: password });

      if (user) {
        return new _emberCliMirage['default'].Response(201, {}, { token: user.id });
      } else {
        var body = { errors: [{ detail: 'Email or password is invalid' }] };

        return new _emberCliMirage['default'].Response(400, JSON_API_HEADERS, body);
      }
    });

    // Search

    this.post('search', function (schema, _ref4) {
      var queryParams = _ref4.queryParams;
      var entityType = queryParams['entity-type'];

      var searchHandler = ({
        company: _frontendMirageUtilsSearch.handleCompanySearch,
        lead: _frontendMirageUtilsSearch.handleLeadSearch,
        user: _frontendMirageUtilsSearch.handleUserSearch,
        global: _frontendMirageUtilsSearch.handleGlobalSearch
      })[entityType || 'global'];

      return searchHandler(queryParams, schema);
    });

    // Notes
    this.post('notes');
    this.get('notes', function (_ref5, _ref6) {
      var notes = _ref5.notes;
      var leadId = _ref6.queryParams['lead-id'];

      if (leadId) {
        var collection = notes.where({ leadId: leadId });

        return collection;
      }

      return new _emberCliMirage['default'].Response(422, JSON_API_HEADERS, { errors: [{ detail: 'No `lead-id` query parameter was passed' }] });
    });

    // Users

    this.get('/users', function (_ref7, request) {
      var users = _ref7.users;

      if (Object.keys(request.queryParams).length) {
        return users.where(request.queryParams);
      } else {
        return users.all();
      }
    });
    this.get('/users/:id');
    this.patch('/users/:id');
    this.post('/users');

    this.get('/users/me', function (_ref8, request) {
      var users = _ref8.users;

      var id = request.requestHeaders.Authorization.split(' ')[1];

      return users.find(id);
    });

    this.post('/users/verify_token', {}, 200);
  };
});
/* eslint-disable ember-suave/prefer-destructuring */