define('frontend/mirage/scenarios/activity', ['exports'], function (exports) {
  function generateActivityForCompany(server, company, eventType, detail) {
    var externalRep = server.create('user', { company: company });
    var lead = server.create('lead', { company: company, externalRep: externalRep });
    server.create('activityLog', { detail: detail, eventType: eventType, lead: lead, actor: externalRep });
  }
  function generateActivityForLead(server, lead, eventType, detail, actor) {
    server.create('activityLog', { detail: detail, eventType: eventType, lead: lead, actor: actor || lead.externalRep });
  }
  function generateActivityForUser(server, user, eventType, detail) {
    var lead = server.create('lead', { company: user.company, externalRep: user });
    server.create('activityLog', { detail: detail, eventType: eventType, lead: lead, actor: user });
  }

  exports.generateActivityForCompany = generateActivityForCompany;
  exports.generateActivityForLead = generateActivityForLead;
  exports.generateActivityForUser = generateActivityForUser;
});