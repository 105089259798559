define('frontend/components/svg-icon/component', ['exports', 'ember-component', 'ember-computed', 'frontend/config/environment'], function (exports, _emberComponent, _emberComputed, _frontendConfigEnvironment) {
  var rootURL = _frontendConfigEnvironment['default'].rootURL;

  var SYMBOL_FILE_PATH_BASE = 'assets/icons.svg';

  /**
   * A Component that wraps an `<svg>` element and renders a `<use>`
   * tag, which references a specified icon file name.
   *
   * The intent is to make use of SVG Symbols for textual icons.
   *
   * @public
   */
  var SVGIcon = _emberComponent['default'].extend({
    tagName: 'svg',
    attributeBindings: ['id', 'aria-hidden', 'version', 'viewBox', 'xmlns',

    // special syntax for namespaced attributes (https://github.com/emberjs/ember.js/pull/10186#discussion_r22911832)
    'xmlnsXlink:xmlns:xlink', 'x', 'y', 'width', 'height', 'stroke', 'stroke-width', 'fill', 'preserveAspectRatio', 'style'],

    version: '1.1',

    // @see: https://github.com/WebDevStudios/wd_s/issues/168
    'aria-hidden': 'true',

    width: '1em',
    height: '1em',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
    stroke: undefined,
    fill: undefined,
    'stroke-width': '0.125rem',

    fileName: '',

    rootURL: rootURL,

    iconPathPrefix: (0, _emberComputed['default'])('rootURL', {
      get: function get() {
        return '' + this.get('rootURL') + SYMBOL_FILE_PATH_BASE;
      }
    }).readOnly(),

    filePath: (0, _emberComputed['default'])('fileName', 'iconPathPrefix', {
      get: function get() {
        return this.get('iconPathPrefix') + '#' + this.get('fileName');
      }
    }).readOnly()
  });

  SVGIcon.reopenClass({
    positionalParams: ['fileName']
  });

  exports['default'] = SVGIcon;
});