define('frontend/routes/leads/open', ['exports', 'ember-route', 'ember-service/inject', 'ember-computed', 'frontend/routes/leads/shared/actions', 'frontend/utils/lead-states'], function (exports, _emberRoute, _emberServiceInject, _emberComputed, _frontendRoutesLeadsSharedActions, _frontendUtilsLeadStates) {
  exports['default'] = _emberRoute['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),

    user: (0, _emberComputed.readOnly)('currentUser.user'),

    activate: function activate() {
      var user = this.get('user');

      if (user.get('isInternalSalesperson')) {
        this.transitionTo('leads');
      }
    },
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var number = _ref.page;
      var size = _ref.size;

      var state = 'open';

      return this.store.query('lead', {
        state: state,
        page: {
          page: number,
          'page_size': size
        }
      });
    },

    actions: {
      viewLead: _frontendRoutesLeadsSharedActions.viewLead,

      onLeadNextStepSelected: function onLeadNextStepSelected(lead, nextStep) {
        if ([_frontendUtilsLeadStates.WON, _frontendUtilsLeadStates.LOST].includes(nextStep)) {
          this.send('closeLead', lead, nextStep);
        } else {
          this.send('updateLeadNextStep', lead, nextStep);
        }
      },

      updateLeadNextStep: function updateLeadNextStep(lead, nextStep) {
        lead.set('nextStep', nextStep);
        lead.save();
      },

      closeLead: function closeLead(lead, finalState) {
        lead.set('state', finalState);
        this.transitionTo('closed-lead', lead);
      }
    }
  });
});