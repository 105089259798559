define('frontend/helpers/is-blink', ['exports', 'ember-browser-checker/helpers/is-blink'], function (exports, _emberBrowserCheckerHelpersIsBlink) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsBlink['default'];
    }
  });
  Object.defineProperty(exports, 'isBlink', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsBlink.isBlink;
    }
  });
});