define('frontend/components/leads-table/next-sales-step/component', ['exports', 'ember-component', 'ember-computed', 'frontend/utils/lead-states', 'frontend/utils/lead-next-steps'], function (exports, _emberComponent, _emberComputed, _frontendUtilsLeadStates, _frontendUtilsLeadNextSteps) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _emberComponent['default'].extend({
    lead: (0, _emberComputed.readOnly)('row.content'),
    nextStepOptions: [].concat(_toConsumableArray(_frontendUtilsLeadNextSteps['default']), [_frontendUtilsLeadStates.WON, _frontendUtilsLeadStates.LOST]),

    click: function click(event) {
      event.stopPropagation();
    }
  });
});