define('frontend/components/login-form/component', ['exports', 'ember-component', 'ember-service/inject'], function (exports, _emberComponent, _emberServiceInject) {
  exports['default'] = _emberComponent['default'].extend({
    flashMessages: (0, _emberServiceInject['default'])(),
    session: (0, _emberServiceInject['default'])(),
    store: (0, _emberServiceInject['default'])(),

    actions: {
      login: function login(changeset) {
        var _this = this;

        var email = changeset.get('email');
        var password = changeset.get('password');
        var session = this.get('session');

        if (email) {
          email = email.toLowerCase();
        }

        return session.authenticate('authenticator:custom', email, password)['catch'](function (response) {
          _this.get('flashMessages').danger(response.payload.errors[0]);
        });
      }
    }
  });
});