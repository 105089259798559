define('frontend/controllers/dealer-lookup', ['exports', 'ember-controller', 'ember-computed', 'ember-service/inject'], function (exports, _emberController, _emberComputed, _emberServiceInject) {
  exports['default'] = _emberController['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    flashMessages: (0, _emberServiceInject['default'])(),
    currentUser: (0, _emberServiceInject['default'])(),
    user: (0, _emberComputed.readOnly)('currentUser.user'),
    isAdmin: (0, _emberComputed.bool)('user.isAdmin'),
    zip: null,
    companies: null,
    isSearching: false,
    noQuery: (0, _emberComputed['default'])('zip', 'companies', function () {
      return this.get('zip') === null && this.get('companies') === null;
    }),
    noSearch: (0, _emberComputed['default'])('zip', function () {
      return this.get('zip') !== null && this.get('companies') === null;
    }),
    companyResult: (0, _emberComputed['default'])('companies', function () {
      return this.get('companies') !== null && Array.isArray(this.get('companies')) === true;
    }),
    actions: {
      updateZip: function updateZip(e) {
        this.set('zip', e.target.value);
      },
      searchFor: function searchFor() {
        var _this = this;

        return this.store.query('company', { zip: this.get('zip') }).then(function (companies) {
          _this.set('zip', null);
          return _this.set('companies', companies);
        });
      }
    }
  });
});