define('frontend/initializers/ember-cli-bugsnag', ['exports', 'frontend/config/environment', 'bugsnag', 'ember-cli-bugsnag/utils/bugsnag-configuration'], function (exports, _frontendConfigEnvironment, _bugsnag, _emberCliBugsnagUtilsBugsnagConfiguration) {
  exports['default'] = {
    name: 'ember-cli-bugsnag',

    initialize: function initialize() {
      var configVariables = _frontendConfigEnvironment['default'].bugsnag || {};
      var releaseStage = _frontendConfigEnvironment['default'].bugsnag.releaseStage || _frontendConfigEnvironment['default'].environment;

      // Set currentRevision value as Bugsnag appVersion
      configVariables.appVersion = _frontendConfigEnvironment['default'].currentRevision || _frontendConfigEnvironment['default'].APP.version;

      if (typeof FastBoot === 'undefined') {
        new _emberCliBugsnagUtilsBugsnagConfiguration['default'](configVariables, releaseStage).apply(_bugsnag['default']);
      }
    }
  };
});