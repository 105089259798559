define('frontend/utils/range', ['exports'], function (exports) {
  exports['default'] = range;

  function range(_start, _end) {
    // Allow a single argument to be treated a range from 0 to n
    var start = typeof _end === 'undefined' ? 0 : _start;
    var end = typeof _end === 'undefined' ? _start : _end;

    var size = Math.abs(end - start) + 1;

    return Array.from({ length: size }, function (item, idx) {
      return start + idx;
    });
  }
});