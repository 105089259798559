define('frontend/routes/settings/users', ['exports', 'ember-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject', 'rsvp'], function (exports, _emberRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberServiceInject, _rsvp) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: (0, _emberServiceInject['default'])(),

    model: function model() {
      var query = {};

      if (this.get('currentUser.user.isAdmin')) {
        return _rsvp['default'].hash({
          inactive: this.store.query('user', { role: 'inactive' }),
          disabled: this.store.query('user', { role: 'disabled' })
        });
      }

      return this.store.query('user', query);
    }
  });
});