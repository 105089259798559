define('frontend/helpers/is-edge', ['exports', 'ember-browser-checker/helpers/is-edge'], function (exports, _emberBrowserCheckerHelpersIsEdge) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsEdge['default'];
    }
  });
  Object.defineProperty(exports, 'isEdge', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsEdge.isEdge;
    }
  });
});