define('frontend/routes/settings/companies', ['exports', 'ember-concurrency', 'ember-route', 'ember-service/inject', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _emberConcurrency, _emberRoute, _emberServiceInject, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: (0, _emberServiceInject['default'])(),

    search: (0, _emberServiceInject['default'])(),
    session: (0, _emberServiceInject['default'])(),

    activate: function activate() {
      if (!this.get('currentUser.user.isAdmin')) {
        this.transitionTo('settings.users');
        return;
      }

      this.controllerFor('settings').set('displayNav', true);
    },

    model: function model() {
      return {
        loadCompaniesTask: this.get('fetchCompanies').perform()
      };
    },

    fetchCompanies: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var role, limitedRoles, myCompany;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            role = this.get('currentUser.user.role');
            limitedRoles = ['dealer_manager', 'dealer_salesperson'];

            if (!limitedRoles.includes(role)) {
              context$1$0.next = 9;
              break;
            }

            myCompany = this.get('currentUser.user.company.content.id');
            context$1$0.next = 6;
            return this.store.findRecord('company', myCompany);

          case 6:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 9:
            context$1$0.next = 11;
            return this.store.findAll('company');

          case 11:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 12:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    searchUsers: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(queryOpts) {
      var searchService, query;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            searchService = this.get('search');
            query = queryOpts.query;

            if (!(query === '')) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 5;
            return [];

          case 5:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 6:
            context$1$0.next = 8;
            return searchService.get('searchEntity').perform('user', queryOpts);

          case 8:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 9:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).cancelOn('deactivate'),

    actions: {
      searchUsers: function searchUsers(searchQuery) {
        var queryOpts = {
          'entity-type': 'user',
          query: searchQuery
        };

        return this.get('searchUsers').perform(queryOpts);
      },

      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});