define('frontend/models/lead', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-computed', 'ember-data/relationships', 'ember-moment/computeds/moment', 'frontend/utils/lead-states'], function (exports, _emberDataModel, _emberDataAttr, _emberComputed, _emberDataRelationships, _emberMomentComputedsMoment, _frontendUtilsLeadStates) {
  exports['default'] = _emberDataModel['default'].extend({
    businessName: (0, _emberDataAttr['default'])('string'),
    claimed: (0, _emberDataAttr['default'])('boolean'),
    closedReason: (0, _emberDataAttr['default'])('string'),
    email: (0, _emberDataAttr['default'])('string'),
    dealerReferral: (0, _emberDataAttr['default'])('string'),
    firstName: (0, _emberDataAttr['default'])('string'),
    insertedAt: (0, _emberDataAttr['default'])('date'),
    lastName: (0, _emberDataAttr['default'])('string'),
    leadDetails: (0, _emberDataAttr['default'])('string'),
    leadUrl: (0, _emberDataAttr['default'])('string'),
    localDealer: (0, _emberDataAttr['default'])('boolean'),
    message: (0, _emberDataAttr['default'])('string'),
    phone: (0, _emberDataAttr['default'])('string'),
    poNumber: (0, _emberDataAttr['default'])('string'),
    poAmount: (0, _emberDataAttr['default'])('number'),
    printable: (0, _emberDataAttr['default'])('boolean'),
    projectReference: (0, _emberDataAttr['default'])('string'),
    quotable: (0, _emberDataAttr['default'])('boolean'),
    quoteAmount: (0, _emberDataAttr['default'])('number'),
    quoteNumber: (0, _emberDataAttr['default'])('string'),
    referenceNumber: (0, _emberDataAttr['default'])('string'),
    specifications: (0, _emberDataAttr['default'])('string'), // AWS URL for PDF
    printed: (0, _emberDataAttr['default'])('boolean'),
    claimableByInternalRep: (0, _emberDataAttr['default'])('boolean'),
    activityDate: (0, _emberDataAttr['default'])('simple-date'),

    // Map Related
    lat: (0, _emberDataAttr['default'])('number', { defaultValue: 10.6346 }),
    lng: (0, _emberDataAttr['default'])('number', { defaultValue: -85.4407 }),

    // Possible States:
    // New, Assigned, Open, Price, (Won or Lost)
    state: (0, _emberDataAttr['default'])('string'),
    nextStep: (0, _emberDataAttr['default'])('string'),
    zip: (0, _emberDataAttr['default'])('string'),

    // Scenarios

    // If company, externalRep, internalRep are empty
    // It belongs to an admin (new lead)

    // If company exists, but externalRep and internalRep are empty
    // Admin has assigned it to a company and action is now on a dealer manager

    // If company and externalRep exists
    // It has been assigned and claimed by an external rep (salesperson)
    //  External Rep may set state to Quote
    //  This should automatically kicks it back to the admin

    // If internalRep exists and company and externalRep are empty
    // Admin has assigned it to an internal salesperson from the start
    company: (0, _emberDataRelationships.belongsTo)('company'),
    externalRep: (0, _emberDataRelationships.belongsTo)('user'),
    internalRep: (0, _emberDataRelationships.belongsTo)('user'),

    leadSource: (0, _emberDataRelationships.belongsTo)('lead-source'),

    activityLogs: (0, _emberDataRelationships.hasMany)('activityLog', { inverse: 'lead' }),
    attachments: (0, _emberDataRelationships.hasMany)('attachment'),

    externalRepName: (0, _emberComputed.readOnly)('externalRep.fullName'),

    fullName: (0, _emberComputed['default'])('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    displayName: (0, _emberComputed.alias)('fullName'),

    insertedAtMoment: (0, _emberMomentComputedsMoment['default'])('insertedAt'),

    leadSourceName: (0, _emberComputed.readOnly)('leadSource.value'),

    requestLocalDealer: (0, _emberComputed['default'])('localDealer', function () {
      return this.get('localDealer') ? 'Yes' : 'No';
    }),

    isInNewState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].NEW),
    isInAssignedState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].ASSIGNED),
    isInLostState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].LOST),
    isInOpenState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].OPEN),
    isInWonState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].WON),
    isInPriceState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].PRICE),
    isInWaitingState: (0, _emberComputed.equal)('state', _frontendUtilsLeadStates['default'].WAITING),

    isInClosedState: (0, _emberComputed.or)('isInWonState', 'isInLostState')

  });
});