define('frontend/routes/leads/my', ['exports', 'ember-route', 'ember-service/inject', 'ember-computed', 'frontend/routes/leads/shared/actions'], function (exports, _emberRoute, _emberServiceInject, _emberComputed, _frontendRoutesLeadsSharedActions) {
  exports['default'] = _emberRoute['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),

    user: (0, _emberComputed.readOnly)('currentUser.user'),

    activate: function activate() {
      var user = this.get('user');

      if (!user.get('isInternalSalesperson')) {
        this.transitionTo('leads');
      }
    },

    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },

    model: function model(_ref) {
      var number = _ref.page;
      var size = _ref.size;

      var state = 'my';

      return this.store.query('lead', {
        state: state,
        page: {
          page: number,
          'page_size': size
        }
      });
    },

    actions: {
      viewLead: _frontendRoutesLeadsSharedActions.viewLead
    },

    _filterLeads: function _filterLeads(lead) {
      var userId = this.get('user.id');
      var internalRepId = lead.get('internalRep.id');
      var leadIsNotClosed = !lead.get('isInClosedState');
      var leadBelongsToCurrentUser = userId === internalRepId;

      return leadIsNotClosed && leadBelongsToCurrentUser;
    }
  });
});