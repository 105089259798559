define('frontend/components/leads-table/lead-actions/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject'], function (exports, _emberComponent, _emberComputed, _emberServiceInject) {
  exports['default'] = _emberComponent['default'].extend({
    currentUser: (0, _emberServiceInject['default'])(),

    lead: (0, _emberComputed.readOnly)('row.content'),
    user: (0, _emberComputed.readOnly)('currentUser.user'),

    isNotClaimed: (0, _emberComputed.not)('lead.claimed'),

    internalRepCanClaim: (0, _emberComputed.and)('user.isInternalSalesperson', 'lead.claimableByInternalRep'),
    dealerCanClaim: (0, _emberComputed.and)('user.isDealerManagerOrSalesperson', 'isNotClaimed'),
    canBeClaimed: (0, _emberComputed.or)('internalRepCanClaim', 'dealerCanClaim'),

    rowValue: (0, _emberComputed['default'])('row', function () {
      return this.get('lead.firstName');
    }),

    click: function click(event) {
      event.stopPropagation();
    }
  });
});