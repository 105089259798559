define('frontend/components/leads-table/lead-actions-assigned/component', ['exports', 'ember', 'ember-component', 'ember-computed', 'ember-service/inject', 'rsvp'], function (exports, _ember, _emberComponent, _emberComputed, _emberServiceInject, _rsvp) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _emberComponent['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),

    onAssigned: function onAssigned() {},

    lead: (0, _emberComputed.readOnly)('row.content'),

    click: function click(event) {
      event.stopPropagation();
    },

    actions: {
      assign: function assign() {
        var ajax = this.get('ajax');
        var lead = this.get('lead');
        var url = '/leads/' + lead.id + '/price';
        var method = 'POST';
        var options = { method: method };
        var leadId = lead.get('id');
        var router = getOwner(this).lookup('router:main');

        lead.set('printed', true);

        var promises = [lead.save(), ajax.request(url, options)];

        _rsvp['default'].all(promises).then(function () {
          return router.transitionTo('lead-print', leadId);
        });
      }
    }
  });
});