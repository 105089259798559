define('frontend/transforms/simple-date', ['exports', 'ember-data/transform'], function (exports, _emberDataTransform) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _emberDataTransform['default'].extend({
    deserialize: function deserialize(serialized) {
      var type = typeof serialized;

      if (type === 'string') {
        var _serialized$split = serialized.split('-');

        var _serialized$split2 = _slicedToArray(_serialized$split, 3);

        var year = _serialized$split2[0];
        var month = _serialized$split2[1];
        var day = _serialized$split2[2];

        year = parseInt(year, 10);
        month = parseInt(month, 10) - 1;
        day = parseInt(day, 10);

        return new Date(year, month, day);
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    },

    serialize: function serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString();
      } else {
        return null;
      }
    }
  });
});