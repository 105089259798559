define('frontend/utils/query-param-string', ['exports'], function (exports) {
  exports['default'] = makeQueryParamString;
  /**
   * Helper that returns a URL for the `/search` api when
   * given a hash of query param key-values
   *
   * @public
   */

  function makeQueryParamString(queryOpts) {
    return Object.keys(queryOpts).reduce(function (builtString, paramKey, idx) {
      var paramVal = queryOpts[paramKey];

      return idx === 0 ? paramKey + '=' + paramVal : builtString + '&' + paramKey + '=' + paramVal;
    }, '');
  }
});