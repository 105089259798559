define('frontend/mixins/activity-log-route', ['exports', 'ember-metal/mixin', 'ember-concurrency', 'frontend/utils/activity-event-types'], function (exports, _emberMetalMixin, _emberConcurrency, _frontendUtilsActivityEventTypes) {

  var VALID_EVENT_TYPES = Object.values(_frontendUtilsActivityEventTypes['default']);
  var DEFAULT_LOG_LIMIT = 25;

  exports['default'] = _emberMetalMixin['default'].create({
    fetchActivityLogTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var _ref,
          limit,
          eventType,
          leadId,
          params,
          args$1$0 = arguments;

      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _ref = args$1$0.length <= 0 || args$1$0[0] === undefined ? {} : args$1$0[0];
            limit = _ref.limit;
            eventType = _ref.eventType;
            leadId = _ref.leadId;
            params = { limit: limit || DEFAULT_LOG_LIMIT };

            if (eventType && VALID_EVENT_TYPES.includes(eventType)) {
              params['event-type'] = eventType;
            }

            if (leadId) {
              params['lead-id'] = leadId;
            }

            context$1$0.next = 9;
            return this.store.query('activity-log', params);

          case 9:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    }))
  });
});