define('frontend/services/search', ['exports', 'ember-service', 'ember-service/inject', 'frontend/utils/query-param-string', 'ember-concurrency'], function (exports, _emberService, _emberServiceInject, _frontendUtilsQueryParamString, _emberConcurrency) {
  exports['default'] = _emberService['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    store: (0, _emberServiceInject['default'])(),

    searchGlobally: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var queryOpts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var qpString, url, _ref, responseData;

      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            qpString = (0, _frontendUtilsQueryParamString['default'])(queryOpts);
            url = '/search?' + qpString;
            context$1$0.next = 4;
            return this.get('ajax').request(url, {
              method: 'POST'
            });

          case 4:
            _ref = context$1$0.sent;
            responseData = _ref.data;
            return context$1$0.abrupt('return', responseData || []);

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    searchEntity: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(entityModelName, queryOpts) {
      var store;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            store = this.get('store');
            context$1$0.next = 3;
            return store.query(entityModelName, { search: queryOpts });

          case 3:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    }))
  });
});