define('frontend/models/analyses/history', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({

    // Lead states and their counts
    lost: attr('number'),
    'new': attr('number'),
    open: attr('number'),
    quoted: attr('number'),
    won: attr('number')
  });
});