define('frontend/utils/bugsnag', ['exports', 'ember-cli-bugsnag/utils/bugsnag'], function (exports, _emberCliBugsnagUtilsBugsnag) {
  Object.defineProperty(exports, 'getMetaData', {
    enumerable: true,
    get: function get() {
      return _emberCliBugsnagUtilsBugsnag.getMetaData;
    }
  });
  Object.defineProperty(exports, 'getUser', {
    enumerable: true,
    get: function get() {
      return _emberCliBugsnagUtilsBugsnag.getUser;
    }
  });
});