define('frontend/components/assign-lead-link/component', ['exports', 'ember-component', 'ember-computed', 'ember-object'], function (exports, _emberComponent, _emberComputed, _emberObject) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: '',

    row: null,

    assignTo: (0, _emberComputed['default'])('row.content', function () {
      var assignTo = this.get('row.content');

      return _emberObject['default'].create({
        id: assignTo.id,
        type: assignTo.constructor.modelName
      });
    }),

    lead: (0, _emberComputed.readOnly)('column.lead')
  });
});