define('frontend/routes/analytics', ['exports', 'ember-concurrency', 'ember-route', 'ember-service/inject', 'ember-simple-auth/mixins/authenticated-route-mixin', 'moment', 'rsvp'], function (exports, _emberConcurrency, _emberRoute, _emberServiceInject, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _moment, _rsvp) {
  exports.createPastDates = createPastDates;

  function createPastDates() {
    var dates = Array(12).fill(1).map(function (_, index) {
      var date = (0, _moment['default'])().subtract(index + 1, 'months');
      var year = date.year();
      var month = date.month() + 1;

      return { year: year, month: month };
    });

    dates = dates.reverse();

    return dates;
  }

  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    session: (0, _emberServiceInject['default'])(),
    currentUser: (0, _emberServiceInject['default'])(),

    queryParams: {
      company_id: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var canView = this.get('currentUser.user.isAdmin') || this.get('currentUser.user.isDealerManager') || this.get('currentUser.user.reportingAccess');
      if (!canView) {
        this.replaceWith('index');
      }
    },

    model: function model(params) {
      var currentUser = this.get('currentUser.user');

      params = this._parseParams(params);

      var pipelineValueTask = this.get('pipelineValueTask').perform(Object.assign({}, params));
      var historyByDaysTask = this.get('historyByDaysTask').perform(Object.assign({}, params));
      var historyOfMonthsTask = this.get('historyOfMonthsTask').perform(Object.assign({}, params));

      var tasks = { pipelineValueTask: pipelineValueTask, historyByDaysTask: historyByDaysTask, historyOfMonthsTask: historyOfMonthsTask };

      if (currentUser.get('isAdmin') || currentUser.get('isInternalSalesperson')) {
        tasks.companiesTask = this.get('companiesFetchTask').perform();
      }

      return tasks;
    },

    pipelineValueTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.store.queryRecord('analyses/pipeline-value', params);

          case 2:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    historyByDaysTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.store.queryRecord('analyses/history', Object.assign({}, { days: 30 }, params));

          case 2:
            context$1$0.t0 = context$1$0.sent;
            context$1$0.next = 5;
            return this.store.queryRecord('analyses/history', Object.assign({}, { days: 90 }, params));

          case 5:
            context$1$0.t1 = context$1$0.sent;
            context$1$0.next = 8;
            return this.store.queryRecord('analyses/history', Object.assign({}, { days: 365 }, params));

          case 8:
            context$1$0.t2 = context$1$0.sent;
            return context$1$0.abrupt('return', {
              by30: context$1$0.t0,
              by90: context$1$0.t1,
              by365: context$1$0.t2
            });

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    historyOfMonthsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var dates, queryParams, queries;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            dates = createPastDates();
            queryParams = dates.map(function (date) {
              return Object.assign({}, date, params);
            });
            queries = queryParams.map(function (query) {
              return _this.store.queryRecord('analyses/history', query);
            });
            context$1$0.next = 5;
            return _rsvp['default'].all(queries);

          case 5:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),

    companiesFetchTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.store.findAll('company');

          case 2:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    _parseParams: function _parseParams(routeParams) {
      var currentUser = this.get('currentUser.user');
      var currentUserCompanyId = currentUser.get('company.id');
      var company_id = routeParams.company_id;

      // dealer managers will ONLY use the analytics dashboard for their own company
      if (currentUser.get('isDealerManager')) {
        return { company_id: currentUserCompanyId };
      }

      // Reporting Access Users will have similar permissions to Admin
      // admins will have the ability to view analytics for a particular company
      if (currentUser.get('reportingAccess') || currentUser.get('isAdmin')) {
        if (company_id === currentUserCompanyId) {
          return {};
        }

        if (company_id === null) {
          return {};
        }

        return { company_id: company_id };
      }

      return {};
    },

    actions: {
      onCompanyChange: function onCompanyChange(company) {
        var currentUser = this.get('currentUser.user');
        var isAdmin = currentUser.get('isAdmin');
        var currentUserCompanyId = currentUser.get('company.id');
        var selectedCompanyId = company.get('id');
        var controller = this.controllerFor(this.routeName);

        if (isAdmin && currentUserCompanyId === selectedCompanyId) {
          return controller.set('company_id', null);
        }

        return controller.set('company_id', selectedCompanyId);
      }
    }
  });
});
/* eslint-disable camelcase */