define('frontend/helpers/form-for/humanize', ['exports', 'ember-form-for/helpers/form-for/humanize'], function (exports, _emberFormForHelpersFormForHumanize) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFormForHelpersFormForHumanize['default'];
    }
  });
  Object.defineProperty(exports, 'formForHumanize', {
    enumerable: true,
    get: function get() {
      return _emberFormForHelpersFormForHumanize.formForHumanize;
    }
  });
});