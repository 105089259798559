define('frontend/components/leads-table/component', ['exports', 'ember-component', 'ember-computed', 'ember-service/inject', 'frontend/mixins/table-common'], function (exports, _emberComponent, _emberComputed, _emberServiceInject, _frontendMixinsTableCommon) {
  exports['default'] = _emberComponent['default'].extend(_frontendMixinsTableCommon['default'], {
    currentUser: (0, _emberServiceInject['default'])(),
    lead: (0, _emberComputed.readOnly)('row.content'),
    user: (0, _emberComputed.readOnly)('currentUser.user'),

    isNotClaimed: (0, _emberComputed.not)('lead.claimed'),

    internalRepCanClaim: (0, _emberComputed.and)('user.isInternalSalesperson', 'lead.claimableByInternalRep'),
    dealerCanClaim: (0, _emberComputed.and)('user.isDealerManagerOrSalesperson', 'isNotClaimed'),
    canBeClaimed: (0, _emberComputed.or)('internalRepCanClaim', 'dealerCanClaim'),
    canAutoClaim: (0, _emberComputed.and)('user.isDealerSalesperson', 'isNotClaimed'),

    columnTypes: {
      newLeads: function newLeads() {
        return [{
          label: 'Action',
          cellComponent: 'leads-table/lead-actions'
        }];
      },
      quotableLeads: function quotableLeads() {
        return [{
          label: 'Action',
          cellComponent: 'leads-table/lead-actions-assigned'
        }];
      },
      openLeads: function openLeads() {
        var columns = [{
          label: 'Next Sales Step',
          cellComponent: 'leads-table/next-sales-step'
        }];
        if (this.get('currentUser.user.isInternalSalesperson')) {
          columns.unshift({
            label: 'Dealer',
            valuePath: 'company.name'
          });
        }
        return columns;
      },
      assignedLeads: function assignedLeads() {
        return [{
          label: 'Action',
          cellComponent: 'leads-table/lead-actions-assigned'
        }];
      }
    },

    closeLead: function closeLead() {},
    updateLeadNextStep: function updateLeadNextStep() {},

    columns: (0, _emberComputed['default'])(function () {
      var typeSpecificColumns = this.columnTypes[this.tableType];
      return [{
        label: 'Reference Number',
        valuePath: 'referenceNumber'
      }, {
        label: 'Name',
        valuePath: 'displayName'
      }, {
        label: 'Assigned To',
        valuePath: 'company.name'
      }, {
        label: 'Rep',
        valuePath: 'externalRep.displayName'
      }, {
        label: 'Quote Number',
        valuePath: 'quoteNumber'
      }, {
        label: 'Date',
        valuePath: 'insertedAt',
        cellComponent: 'display-date'
      }].concat(typeSpecificColumns ? typeSpecificColumns.call(this) : []);
    }),

    actions: {
      claimLead: function claimLead(row) {
        this.claimLead(row.get('content'));
      },

      rowClicked: function rowClicked(row) {
        this.selectRow(row.get('id'));
      }
    }
  });
});