define('frontend/components/animated-efficiency-score/component', ['exports', 'ember-component', 'ember-computed', 'ember-runloop', 'ember-concurrency', 'ember-utils'], function (exports, _emberComponent, _emberComputed, _emberRunloop, _emberConcurrency, _emberUtils) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _emberComponent['default'].extend({
    classNames: ['animated-efficiency-score'],

    animationDelay: 100,
    animationInterval: 125,
    iconClassName: '',

    iconContainerElem: null,
    iconElems: null,

    _iconClassName: 'score-icon',

    /**
     * A computed class name that we'll yield back to the block scope
     * as `iconClassName`. Add `iconClassName` to the `class` of any of the icons that
     * you want animated.
     *
     * @private
     */
    _computedIconClassName: (0, _emberComputed['default'])('iconClassName', function () {
      return this.get('_iconClassName') + ' ' + this.get('iconClassName');
    }),

    /**
     * Adds the "is-visible" class to each of our containing content's
     * top-level element, then proceeds to perform individual icon
     * animations.
     *
     * @public
     */
    animationTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function animateIcons(iconContainerElem, iconElems) {
      var animationDelay, iconAnimationTask;
      return regeneratorRuntime.wrap(function animateIcons$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            animationDelay = this.get('animationDelay');
            iconAnimationTask = this.get('iconAnimationTask');
            context$1$0.next = 4;
            return (0, _emberConcurrency.timeout)(animationDelay);

          case 4:

            iconContainerElem.classList.add('is-visible');

            iconElems.forEach(function (iconElem) {
              return iconAnimationTask.perform(iconElem);
            });

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, animateIcons, this);
    })),

    /**
     * Adds the "is-visible" class to each of our containing content's
     * animatable icons at a staggered interval.
     *
     * @public
     */
    iconAnimationTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function animateIcon(iconElem) {
      return regeneratorRuntime.wrap(function animateIcon$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(this.get('animationInterval'));

          case 2:

            iconElem.classList.add('is-visible');

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, animateIcon, this);
    })).enqueue(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var iconClassName = this.get('_iconClassName');
      var element = this.element;

      this.set('iconContainerElem', element.firstElementChild);
      this.set('iconElems', [].concat(_toConsumableArray(element.querySelectorAll('.' + iconClassName))));

      (0, _emberRunloop.scheduleOnce)('afterRender', this, '_setupIconAnimation');
    },

    _setupIconAnimation: function _setupIconAnimation() {
      var iconContainerElem = this.get('iconContainerElem');
      var iconElems = this.get('iconElems');

      if (!(0, _emberUtils.isEmpty)(iconContainerElem) && !(0, _emberUtils.isEmpty)(iconElems)) {
        this.get('animationTask').perform(iconContainerElem, iconElems);
      }
    }
  });
});