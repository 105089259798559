define('frontend/components/activity-log-viewer/component', ['exports', 'ember-component', 'ember-computed', 'frontend/utils/activity-event-types'], function (exports, _emberComponent, _emberComputed, _frontendUtilsActivityEventTypes) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var values = Object.values;
  exports['default'] = _emberComponent['default'].extend({
    classNames: ['c-list-card'],

    fetchActivityLogTask: null,
    headerTitle: 'Activity Log',
    canFilter: true,
    onLogEventTypeChanged: function onLogEventTypeChanged() {},

    currentEventType: 'All',

    eventTypeOptions: (0, _emberComputed['default'])(function () {
      return [this.get('currentEventType')].concat(_toConsumableArray(values(_frontendUtilsActivityEventTypes['default'])));
    }),

    actions: {
      eventTypeSelected: function eventTypeSelected(type) {
        this.set('currentEventType', type);
        this.get('onLogEventTypeChanged')(type);
      }
    }
  });
});