define('frontend/components/toggle-company-status-cell/component', ['exports', 'ember-component', 'ember-computed', 'frontend/utils/company-statuses'], function (exports, _emberComponent, _emberComputed, _frontendUtilsCompanyStatuses) {
  var values = Object.values;
  exports['default'] = _emberComponent['default'].extend({
    statuses: values(_frontendUtilsCompanyStatuses['default']),
    company: (0, _emberComputed.alias)('row.content'),

    actions: {
      saveStatusChange: function saveStatusChange(status) {
        var company = this.get('company');

        company.set('status', status);
        company.save();
      }
    }
  });
});