define('frontend/components/request-password/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-concurrency'], function (exports, _emberComponent, _emberServiceInject, _emberConcurrency) {
  exports['default'] = _emberComponent['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),

    formSubmitted: false,

    requestPassword: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(changeset) {
      var ajax, url, data, method, options;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            ajax = this.get('ajax');
            url = this._getURL();
            data = this._getData(changeset);
            method = 'POST';
            options = { method: method, data: data };
            context$1$0.next = 7;
            return ajax.request(url, options);

          case 7:

            this.set('formSubmitted', true);

            return context$1$0.abrupt('return', false);

          case 9:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    _getURL: function _getURL() {
      return '/users/recover';
    },

    _getData: function _getData(changeset) {
      var email = changeset.get('email');

      return { email: email };
    }
  });
});