define('frontend/routes/lead', ['exports', 'ember-computed', 'ember-concurrency', 'ember-metal/set', 'ember-route', 'ember-service/inject', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/activity-log-route', 'frontend/utils/lead-states', 'frontend/utils/claim-lead'], function (exports, _emberComputed, _emberConcurrency, _emberMetalSet, _emberRoute, _emberServiceInject, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _frontendMixinsActivityLogRoute, _frontendUtilsLeadStates, _frontendUtilsClaimLead) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _frontendMixinsActivityLogRoute['default'], {
    ajax: (0, _emberServiceInject['default'])(),
    currentUser: (0, _emberServiceInject['default'])(),
    user: (0, _emberComputed.readOnly)('currentUser.user'),

    model: function model(_ref) {
      var leadId = _ref.lead_id;

      return {
        fetchLeadTask: this.get('fetchLeadTask').perform(leadId),
        fetchLeadNotesTask: this.get('fetchLeadNotesTask').perform(leadId),
        fetchLeadActivityLogTask: this.get('fetchActivityLogTask').perform({ leadId: leadId }),
        newNoteModel: this.store.createRecord('note')
      };
    },

    fetchLeadTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(leadId) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.store.findRecord('lead', leadId);

          case 2:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    fetchLeadNotesTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(leadId) {
      var noteRecords;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.store.query('note', { 'lead-id': leadId });

          case 2:
            noteRecords = context$1$0.sent;
            return context$1$0.abrupt('return', noteRecords.toArray());

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    actions: {
      claimLead: function claimLead(lead) {
        (0, _frontendUtilsClaimLead['default'])(this, lead);
      },
      resetLead: function resetLead(changeset) {
        changeset.rollback();
        this.transitionTo('leads');
      },

      saveLead: function saveLead(changeset) {
        return regeneratorRuntime.async(function saveLead$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(changeset.save());

            case 2:
              this.transitionTo('leads');

            case 3:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      updateLeadNextStep: function updateLeadNextStep(changeset, nextStep) {
        return regeneratorRuntime.async(function updateLeadNextStep$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              changeset.set('nextStep', nextStep);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(changeset.validate());

            case 3:
              return context$1$0.abrupt('return', changeset.save());

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      createNote: function createNote(lead, noteChangeset) {
        return regeneratorRuntime.async(function createNote$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              noteChangeset.set('lead', lead);

              context$1$0.next = 3;
              return regeneratorRuntime.awrap(noteChangeset.validate());

            case 3:
              if (!noteChangeset.get('isValid')) {
                context$1$0.next = 8;
                break;
              }

              context$1$0.next = 6;
              return regeneratorRuntime.awrap(noteChangeset.save());

            case 6:

              (0, _emberMetalSet['default'])(this.currentModel, 'newNoteModel', this.store.createRecord('note'));

              return context$1$0.abrupt('return', noteChangeset);

            case 8:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      closeLead: function closeLead(lead, finalState) {
        if ([_frontendUtilsLeadStates.WON, _frontendUtilsLeadStates.LOST].includes(finalState)) {
          lead.set('state', finalState);
          this.transitionTo('closed-lead', lead);
        }
      },

      onRequestQuote: function onRequestQuote(lead) {
        var _this = this;

        var flashMessages = this.get('flashMessages');

        var leadId = lead.get('id');
        var url = '/leads/' + leadId + '/price';
        var method = 'POST';
        var options = { method: method };

        this.get('ajax').request(url, options).then(function () {
          return flashMessages.success('Quote requested from Wirecrafters!');
        })['catch'](function () {
          return flashMessages.danger('Please try again!');
        })['finally'](function () {
          return _this.transitionTo('leads');
        });
      }
    }
  });
});

// await changeset.validate();
// if (changeset.get('isValid')) {
// console.log(changeset);deleteAttachment

// changeset.set('specifications', null);
// changeset._content.firstName = 'a';

// changeset._changes.firstName = 'b';

// changeset.set('specifications', 'http://google.com/a.pdf');
// eslint-disable-next-line no-console
// if (changeset.get('delete_specfication') == true || changeset.get('delete_specfication') == 'true') {
//   changeset.set('specifications', 'delete');
// }