define('frontend/mirage/factories/history', ['exports', 'ember-cli-mirage', 'frontend/utils/range'], function (exports, _emberCliMirage, _frontendUtilsRange) {
  var _faker$list;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var MONTHS = (0, _frontendUtilsRange['default'])(1, 12);
  var MIN_MAX_LEADS = { min: 100, max: 1000 };

  exports['default'] = _emberCliMirage.Factory.extend({
    daysAgo: function daysAgo() {
      return _emberCliMirage.faker.random.number({ min: 1, max: 365 });
    },

    lost: function lost() {
      return _emberCliMirage.faker.random.number(MIN_MAX_LEADS);
    },

    month: (_faker$list = _emberCliMirage.faker.list).cycle.apply(_faker$list, _toConsumableArray(MONTHS)),

    'new': function _new() {
      return _emberCliMirage.faker.random.number(MIN_MAX_LEADS);
    },

    open: function open() {
      return _emberCliMirage.faker.random.number(MIN_MAX_LEADS);
    },

    quoted: function quoted() {
      return _emberCliMirage.faker.random.number(MIN_MAX_LEADS);
    },

    won: function won() {
      return _emberCliMirage.faker.random.number(MIN_MAX_LEADS);
    },

    year: 2017
  });
});