define('frontend/routes/leads', ['exports', 'ember-route', 'ember-service/inject', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/utils/claim-lead'], function (exports, _emberRoute, _emberServiceInject, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _frontendUtilsClaimLead) {
  exports['default'] = _emberRoute['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: (0, _emberServiceInject['default'])(),

    actions: {
      claimLead: function claimLead(lead) {
        (0, _frontendUtilsClaimLead['default'])(this, lead);
      },

      findLeadByReferenceNumber: function findLeadByReferenceNumber(referenceNumber) {
        var results;
        return regeneratorRuntime.async(function findLeadByReferenceNumber$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.store.query('lead', { query: referenceNumber }));

            case 2:
              results = context$1$0.sent;
              return context$1$0.abrupt('return', results.get('firstObject'));

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});