define('frontend/components/leads-page-search-header/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: 'header',
    leadSearchResult: null,
    hasLeadSearchCompleted: false,

    findLeadByReferenceNumber: function findLeadByReferenceNumber() {},
    claimLead: function claimLead() {},

    leadSearchQuery: '',

    hasLeadSearchResult: (0, _emberComputed.notEmpty)('leadSearchResult'),
    hasNoLeadSearchResult: (0, _emberComputed.not)('hasLeadSearchResult'),

    shouldShowEmptySearchResultMessage: (0, _emberComputed.and)('hasLeadSearchCompleted', 'hasNoLeadSearchResult'),

    actions: {
      onLeadSearchInput: function onLeadSearchInput(input) {
        var result;
        return regeneratorRuntime.async(function onLeadSearchInput$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('findLeadByReferenceNumber')(input));

            case 2:
              result = context$1$0.sent;

              this.set('leadSearchResult', result);
              this.set('hasLeadSearchCompleted', true);

            case 5:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },

      searchedLeadClaimed: function searchedLeadClaimed(lead) {
        this.get('claimLead')(lead);
      }
    }
  });
});