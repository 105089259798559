define('frontend/helpers/is-chrome', ['exports', 'ember-browser-checker/helpers/is-chrome'], function (exports, _emberBrowserCheckerHelpersIsChrome) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsChrome['default'];
    }
  });
  Object.defineProperty(exports, 'isChrome', {
    enumerable: true,
    get: function get() {
      return _emberBrowserCheckerHelpersIsChrome.isChrome;
    }
  });
});