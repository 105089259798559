define('frontend/models/activity-log', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
  exports['default'] = _emberDataModel['default'].extend({
    actor: (0, _emberDataAttr['default'])('string'),
    detail: (0, _emberDataAttr['default'])('string'),
    leadId: (0, _emberDataAttr['default'])('string'),
    eventType: (0, _emberDataAttr['default'])('string'),
    insertedAt: (0, _emberDataAttr['default'])('date'),
    target: (0, _emberDataAttr['default'])('string'),
    updatedAt: (0, _emberDataAttr['default'])('date'),

    company: (0, _emberDataRelationships.belongsTo)('company'),
    lead: (0, _emberDataRelationships.belongsTo)('lead', { inverse: 'activityLogs' })
  });
});