define('frontend/mirage/serializers/application', ['exports', 'ember', 'ember-cli-mirage'], function (exports, _ember, _emberCliMirage) {
  var _Ember$String = _ember['default'].String;
  var dasherize = _Ember$String.dasherize;
  var pluralize = _Ember$String.pluralize;
  exports['default'] = _emberCliMirage.JSONAPISerializer.extend({

    /**
     * Allows our mirage models to specify a custom `typeKey`. Otherwise, we'll
     * use the default `modelName`
     *
     * @public
     */
    typeKeyForModel: function typeKeyForModel(model) {
      var typeKey = model.typeKey || model.modelName;

      return dasherize(pluralize(typeKey));
    }
  });
});