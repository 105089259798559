define('frontend/helpers/camelize', ['exports', 'ember-cli-string-helpers/helpers/camelize'], function (exports, _emberCliStringHelpersHelpersCamelize) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersCamelize['default'];
    }
  });
  Object.defineProperty(exports, 'camelize', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersCamelize.camelize;
    }
  });
});