define('frontend/components/star-set/component', ['exports', 'ember-component', 'ember-computed', 'frontend/utils/range'], function (exports, _emberComponent, _emberComputed, _frontendUtilsRange) {
  var min = Math.min;
  exports['default'] = _emberComponent['default'].extend({
    tagName: '',
    fillValue: undefined,
    numStars: undefined,

    emptyColor: '#DFDFDF',
    fillColor: '#ED8A19',
    starWidth: '1em',
    starHeight: '1em',
    svgContainerClassName: '',
    svgClassName: '',

    starData: (0, _emberComputed['default'])('fillValue', 'numStars', function () {
      var numStars = this.get('numStars');
      var fillValue = this.get('fillValue');

      return (0, _frontendUtilsRange['default'])(1, numStars).map(function (star, idx) {
        return {
          // if the index is greater than the total fillValue, we're done filling
          // otherwise, we can take the minimum of 1 or (fillValue - idx) as our
          // decimal percentage.
          fillPct: (idx > fillValue ? 0 : min(1, fillValue - idx)) * 100
        };
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.fillValue = typeof this.fillValue !== 'undefined' ? this.fillValue : 0;
      this.numStars = typeof this.numStars !== 'undefined' ? this.numStars : 5;
    }
  });
});