define('frontend/utils/claim-lead', ['exports', 'frontend/utils/lead-states'], function (exports, _frontendUtilsLeadStates) {

  var claimLead = function claimLead(route, lead) {
    var user = route.get('currentUser.user');
    var userId = user.get('id');
    var isInternalSalesperson = route.get('currentUser.user.isInternalSalesperson');
    var leadId = lead.get('id');
    var isClaimed = lead.get('claimed');

    if (isInternalSalesperson) {
      // if rep already set -- reroute
      var internalRep = lead.get('internalRep');
      if (internalRep.content) {
        var internalRepId = internalRep.content.get('id');
        if (internalRepId == userId) {
          return route.transitionTo('lead', leadId);
        }
      }

      lead.set('internalRep', user);
      lead.save().then(function () {
        return route.transitionTo('lead', leadId);
      });
      return;
    }

    // if rep already claimed -- reroute

    var externalRep = lead.get('externalRep');
    if (externalRep.content && isClaimed) {
      var externalRepId = externalRep.content.get('id');
      if (externalRepId == userId) {
        return route.transitionTo('lead', leadId);
      }
    }

    lead.set('externalRep', user);
    lead.set('claimed', true);
    lead.set('state', _frontendUtilsLeadStates.OPEN);
    lead.save().then(function () {
      return route.transitionTo('lead', leadId);
    });
  };

  exports['default'] = claimLead;
});