define('frontend/components/analytics-by-day-section/component', ['exports', 'ember-component', 'ember-computed', 'ember-cpm/macros/sum', 'ember-cpm/macros/quotient', 'ember-cpm/macros/product'], function (exports, _emberComponent, _emberComputed, _emberCpmMacrosSum, _emberCpmMacrosQuotient, _emberCpmMacrosProduct) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: '',

    section: null,

    chartData: (0, _emberComputed.readOnly)('section.chartData'),
    donutSettings: (0, _emberComputed.readOnly)('section.donutSettings'),
    chartColors: (0, _emberComputed.readOnly)('section.chartColors'),
    chartPadding: (0, _emberComputed.readOnly)('section.chartPadding'),

    leadsWon: (0, _emberComputed.readOnly)('section.leadsWon'),
    leadsLost: (0, _emberComputed.readOnly)('section.leadsLost'),
    numLeads: (0, _emberCpmMacrosSum['default'])('leadsWon', 'leadsLost'),

    _leadsWonRatio: (0, _emberCpmMacrosQuotient['default'])('leadsWon', 'numLeads'),
    _leadsLostRatio: (0, _emberCpmMacrosQuotient['default'])('leadsLost', 'numLeads'),

    leadsWonPct: (0, _emberCpmMacrosProduct['default'])('_leadsWonRatio', 100),
    leadsLostPct: (0, _emberCpmMacrosProduct['default'])('_leadsLostRatio', 100)
  });
});