define('frontend/abilities/users', ['exports', 'ember-service/inject', 'ember-computed', 'ember-can'], function (exports, _emberServiceInject, _emberComputed, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: (0, _emberServiceInject['default'])(),

    user: (0, _emberComputed.readOnly)('currentUser.user'),

    canAdd: (0, _emberComputed.or)('user.isAdmin', 'user.isDealerManager'),
    canEdit: (0, _emberComputed.bool)('user.isAdminOrManager'),
    canEditCompany: (0, _emberComputed.bool)('user.isAdmin')
  });
});