define('frontend/mirage/factories/activity-log', ['exports', 'ember-cli-mirage', 'frontend/utils/activity-event-types'], function (exports, _emberCliMirage, _frontendUtilsActivityEventTypes) {
  var _faker$list;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var values = Object.values;
  var random = Math.random;

  function makeName() {
    var genderCode = random() > 0.5 ? 'm' : 'w';
    var _faker$name = _emberCliMirage.faker.name;
    var firstName = _faker$name.firstName;
    var lastName = _faker$name.lastName;

    return firstName(genderCode) + ' ' + lastName(genderCode);
  }

  exports['default'] = _emberCliMirage.Factory.extend({
    actor: function actor() {
      return makeName();
    },

    detail: function detail() {
      return 'sample detail';
    },

    eventType: (_faker$list = _emberCliMirage.faker.list).random.apply(_faker$list, _toConsumableArray(values(_frontendUtilsActivityEventTypes['default']))),

    insertedAt: function insertedAt() {
      // random timestamp from the past `n` years
      return _emberCliMirage.faker.date.past(1);
    },

    target: function target() {
      return makeName();
    },

    updatedAt: function updatedAt() {
      // random timestamp from the past `n` years
      return _emberCliMirage.faker.date.past(1);
    }

  });
});