define('frontend/components/search-page/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: ['section'],
    classNames: ['body-copy'],

    searchTask: null,

    searchResults: (0, _emberComputed.readOnly)('searchTask.value'),

    _userResultObjects: (0, _emberComputed.filter)('searchResults', function (result) {
      return !!result.user;
    }),

    _leadResultObjects: (0, _emberComputed.filter)('searchResults', function (result) {
      return !!result.lead;
    }),

    _companyResultObjects: (0, _emberComputed.filter)('searchResults', function (result) {
      return !!result.company;
    }),

    leadResults: (0, _emberComputed.mapBy)('_leadResultObjects', 'lead'),
    companyResults: (0, _emberComputed.mapBy)('_companyResultObjects', 'company'),
    userResults: (0, _emberComputed.mapBy)('_userResultObjects', 'user'),

    hasSearchResults: (0, _emberComputed.notEmpty)('searchResults'),
    hasEmptySearchResults: (0, _emberComputed.not)('hasSearchResults'),
    hasNoSearchResults: (0, _emberComputed.none)('searchTask.value'),

    // Even if the response is "empty", "searchTask.value" will still be present when the
    // task has completed
    hasSearchTaskCompleted: (0, _emberComputed.not)('hasNoSearchResults'),

    shouldShowEmptySearchMessage: (0, _emberComputed.and)('hasSearchTaskCompleted', 'hasEmptySearchResults')
  });
});