define('frontend/helpers/form-for/merge-custom-form-field', ['exports', 'ember-form-for/helpers/form-for/merge-custom-form-field'], function (exports, _emberFormForHelpersFormForMergeCustomFormField) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFormForHelpersFormForMergeCustomFormField['default'];
    }
  });
  Object.defineProperty(exports, 'formForMergeCustomFormField', {
    enumerable: true,
    get: function get() {
      return _emberFormForHelpersFormForMergeCustomFormField.formForMergeCustomFormField;
    }
  });
});