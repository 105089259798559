define('frontend/components/leads-search-results-table/component', ['exports', 'ember-component', 'ember-computed', 'frontend/mixins/table-common'], function (exports, _emberComponent, _emberComputed, _frontendMixinsTableCommon) {
  exports['default'] = _emberComponent['default'].extend(_frontendMixinsTableCommon['default'], {
    columns: (0, _emberComputed['default'])(function () {
      var base = [{
        label: 'Reference Number',
        valuePath: 'reference_number'
      }, {
        label: 'Name',
        valuePath: 'display-name'
      }, {
        label: 'Assigned To',
        valuePath: 'company_name'
      }, {
        label: 'Rep',
        valuePath: 'external_rep_name'
      }, {
        label: 'Quote Number',
        valuePath: 'quote_number'
      }, {
        label: 'Date',
        valuePath: 'inserted_at',
        cellComponent: 'display-date'
      }];

      return base;
    }),

    actions: {
      rowClicked: function rowClicked(row) {
        this.selectRow(row.get('id'));
      }
    }
  });
});