define('frontend/routes/recover-user', ['exports', 'ember-route'], function (exports, _emberRoute) {
  exports['default'] = _emberRoute['default'].extend({
    model: function model(_ref) {
      var token = _ref.token;

      return { token: token };
    },

    actions: {
      onSuccess: function onSuccess() {
        this.transitionTo('login');
      }
    }
  });
});