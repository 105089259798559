define('frontend/utils/month-names', ['exports'], function (exports) {
  exports['default'] = getMonthNames;
  var MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  exports.MONTH_NAMES = MONTH_NAMES;
  var MONTH_ABBREVIATIONS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  exports.MONTH_ABBREVIATIONS = MONTH_ABBREVIATIONS;

  function getMonthNames() {
    var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    var startingMonth = opts.startingMonth || 0;
    var abbreviate = !!opts.abbreviate;
    var numMonths = opts.numMonths || 12;

    return Array.from({ length: numMonths }, function (_, idx) {
      var monthIdx = (idx + startingMonth) % 12;

      return abbreviate ? MONTH_ABBREVIATIONS[monthIdx] : MONTH_NAMES[monthIdx];
    });
  }
});