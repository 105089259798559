define('frontend/adapters/analyses/pipeline-value', ['exports', 'frontend/adapters/analyses'], function (exports, _frontendAdaptersAnalyses) {
  exports['default'] = _frontendAdaptersAnalyses['default'].extend({
    analyticsType: 'pipelineValue',

    /**
     * Build a proper URL for the analytics API
     * If a `companyId` is present, this will be appended to the `baseURL`
     *
     * @public
     */
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var companyId = query.companyId;

      var baseURL = this.buildURL(modelName);

      delete query.companyId;

      return companyId ? baseURL + '/' + companyId : '' + baseURL;
    }
  });
});