define('frontend/components/lead-form/component', ['exports', 'ember-component', 'ember-service/inject'], function (exports, _emberComponent, _emberServiceInject) {
  exports['default'] = _emberComponent['default'].extend({
    flashMessages: (0, _emberServiceInject['default'])(),
    actions: {
      addLead: function addLead(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          var isValid = changeset.get('isValid');

          if (isValid) {
            return changeset.save().then(function () {
              _this.get('flashMessages').success('Lead successfully added!');
              _this.resetModel();
            });
          }
        });
      }
    }
  });
});